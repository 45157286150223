import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'ahooks';

// interface
import { getFriendsList, IAttachCertificationData, IFriends, IFriendsListSearchForm, toDeleteFriend } from '../../apis/friends';

// antd
import { Space, Table, Dropdown, Menu, Button, Input, Grid, Pagination, Empty, Card, Modal, List, Tabs } from 'antd';
import { CloseOutlined, ExclamationCircleFilled, PlusOutlined, DownloadOutlined } from '@ant-design/icons';

import BottomNavigatorBar from '../../components/bottomNavigator/bottomNavigator';
import FriendsSearchPage from './search';

const { Column } = Table;
const { useBreakpoint } = Grid;
const { confirm } = Modal;

function FriendsListPage() {
    const navigate = useNavigate();
    const screens = useBreakpoint();

    // 搜索表单
    const [form, setForm] = useState<IFriendsListSearchForm>({
        page: 1,
        size: 10,
    });

    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(IFriends)[]>([]);
    const [mListData, setMListData] = useState<(IFriends)[]>([]);
    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: IFriendsListSearchForm) => {
        let _form = newForm ?? form;
        setLoading(true);
        try {
            let res = await getFriendsList(_form);
            setTotal(res.data.total);
            setMListData(res.data.list);
            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((_form.page! - 1) * _form.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    const resetData = () => {
        let newForm: IFriendsListSearchForm = {
            page: 1,
            size: 10,
        }
        setForm(newForm);
        loadData(newForm);
    }

    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(form.keywords, { wait: 500 });
    useEffect(() => {
        loadData();
    }, [keywordsDebouncedValue]);
    const handleKeywordsChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            keywords: value.target.value
        };
        setForm(newForm);
    }

    // 删除
    const toDelete = (record: IFriends) => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleFilled />,
            content: `确定删除好友 ${record.name} 吗？`,
            onOk() {
                toDeleteFriend({ friend_id: record.id })
                    .then(res => loadData())
                    .catch(e => console.error(e));
            },
            onCancel() { },
        });
    }

    // 添加
    const [toAddModal, setAddModal] = useState<{
        isModalVisible: boolean,
    }>();
    const onAddClose = () => {
        loadData();
        setAddModal({
            isModalVisible: false
        })
    };
    const toAdd = () => {
        if (screens.xs) {
            navigate('/friends/search');
        } else {
            setAddModal({
                isModalVisible: true,
            })
        }
    }

    // 显示附件弹窗
    const [attachmentModalForm, setAttachmentModalForm] = useState<{
        isModalVisible: boolean,
        data: { value: IAttachCertificationData[] }
    }>();
    const showAttachment = (value: IAttachCertificationData[], e?: any) => {
        e?.stopPropagation();
        setAttachmentModalForm({
            isModalVisible: true,
            data: { value }
        })
    }

    return (
        <div className='address inner-page'>
            <div className='search'>
                <Input
                    className="search__item"
                    value={form.keywords}
                    onChange={handleKeywordsChange}
                    placeholder="关键词搜索"
                />

                <Button
                    className="search__button"
                    icon={<CloseOutlined />}
                    loading={loading}
                    onClick={() => resetData()}
                >
                    重置筛选
                </Button>

                <Button
                    type="primary"
                    className={screens.xs ? "search__button" : "search__last-button"}
                    icon={<PlusOutlined />}
                    onClick={toAdd}
                >
                    添加好友
                </Button>
            </div>
            {
                screens.xs
                    ? <div>
                        {
                            mListData.length > 0 ? mListData.map(item =>
                                <Dropdown
                                    trigger={['click']}
                                    menu={{
                                        items: [
                                            // {
                                            //     key: 'detail',
                                            //     disabled: item.status <= 10,
                                            //     label: (
                                            //         <div>查看详情</div>
                                            //     ),
                                            //     onClick: () => toDetail(item),
                                            // },
                                            {
                                                key: 'delete',
                                                label: (
                                                    <div>删除</div>
                                                ),
                                                onClick: () => toDelete(item),
                                            },
                                        ]
                                    }}>
                                    <Card className='m-card'>
                                        <div className='m-card_item'>
                                            <div>姓名：</div>
                                            <div>{item.name}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>联系方式：</div>
                                            <div>{item.mobile ?? '无'}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>资质认证：</div>
                                            <div>
                                                {
                                                    item.attach_certification_data_info && item.attach_certification_data_info.length > 0
                                                        ? <Button
                                                            size='small'
                                                            type='link'
                                                            onClick={(e) => showAttachment(item.attach_certification_data_info!, e)}
                                                        >查看</Button>
                                                        : '无'
                                                }
                                            </div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>平均评分：</div>
                                            <div>{item.avg_appraise}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>合作次数：</div>
                                            <div>{item.cooperation_num}</div>
                                        </div>
                                    </Card>
                                </Dropdown>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                            simple
                        />
                    </div>
                    : <Table
                        dataSource={listData}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column title="姓名" dataIndex="name" key="name" />
                        <Column title="联系方式" dataIndex="mobile" key="mobile" />
                        <Column title="资质认证" dataIndex="attach_certification_data_info" key="attach_certification_data_info"
                            render={(value: IAttachCertificationData[] | null) => (
                                value?.length ?? 0 > 0
                                    ? <Button size='small' onClick={() => showAttachment(value!)}>查看</Button>
                                    : '无'
                            )} />
                        <Column title="平均评分" dataIndex="avg_appraise" key="avg_appraise" />
                        <Column title="合作次数" dataIndex="cooperation_num" key="mobile" />
                        <Column
                            title="操作"
                            key="action"
                            fixed="right"
                            render={(_: any, record: IFriends) => (
                                <Space>
                                    <Dropdown.Button
                                        trigger={['click']}
                                        size='small'
                                        type="primary"
                                        // loading={}
                                        overlay={
                                            <Menu
                                                items={[
                                                    {
                                                        key: 'delete',
                                                        label: (
                                                            <div>删除</div>
                                                        ),
                                                        onClick: () => toDelete(record),
                                                    },
                                                ]}
                                            />
                                        }>
                                        操作
                                    </Dropdown.Button>
                                </Space>
                            )}
                        />
                    </Table>
            }

            {screens.xs ? <BottomNavigatorBar current={3} /> : null}

            {
                toAddModal ? <Modal
                    maskClosable={false}
                    visible={toAddModal.isModalVisible}
                    title={`添加好友`}
                    onCancel={onAddClose}
                    width={1000}
                    footer={null}
                >
                    <FriendsSearchPage></FriendsSearchPage>
                </Modal> : null
            }

            <Modal
                title="附件列表"
                open={attachmentModalForm?.isModalVisible}
                onCancel={() => {
                    setAttachmentModalForm({
                        ...attachmentModalForm!,
                        isModalVisible: false,
                    })
                }}
                footer={
                    <Button key="back" onClick={() => {
                        setAttachmentModalForm({
                            ...attachmentModalForm!,
                            isModalVisible: false,
                        })
                    }}>关闭</Button>
                }
            >
                {
                    attachmentModalForm && attachmentModalForm.data.value.length > 0
                    && <Tabs
                        type="card"
                        items={attachmentModalForm.data.value.map((item, i) => {
                            return {
                                label: item.name,
                                key: String(item.value),
                                children: (
                                    <List
                                        className='record__attachment'
                                        itemLayout="horizontal"
                                        dataSource={item.files_info}
                                        renderItem={item => (
                                            <li>
                                                <Card bodyStyle={{ padding: 10 }}>
                                                    <div className='record__attachment__card'>
                                                        <div>{item.attach_name}</div>
                                                        <Button type="text" icon={<DownloadOutlined />} href={item.attach_url_link} target="_blank" />
                                                    </div>
                                                </Card>
                                            </li>
                                        )}
                                    />
                                ),
                            };
                        })}
                    />
                }
            </Modal>
        </div>
    );
}

export default FriendsListPage;