import { axiosInstance, CommonResponse } from ".";
import { Attachment } from "./attachment";
import { Pager } from "./common";
import { ICompany } from "./company";
import { IUserInfo } from "./user";

export interface IProcessStep {
    id: number,
    order_id: number,
    process_step_id: number,
    process_step_title: string,
    process_step_desc?: string,
    process_step_is_location: boolean,
    process_step_sort: number,
    process_step_attach_files: number[],
    status_text?: null,
    process_step_attach_files_info: Attachment[]
}

export interface IOrder {
    id: number,
    title: string,
    money: number,
    company_id: number,
    company: ICompany,
    status: number,
    process_id: number,
    process_title?: string,
    can_follow_up: boolean,
    status_text: string,
    company_text: string,
    current_process_step: IProcessStep,
    current_process_step_adviser: IUserInfo[],
}

export interface IOrderList {
    list: IOrder[],
    total: number
}

export interface IOrderListSearchForm extends Pager {
    keywords?: string
}

/**
 * 获取业务列表
 * @returns 
 */
export const getOrderList = (data: IOrderListSearchForm): Promise<CommonResponse<IOrderList>> => {
    return axiosInstance.post('/adviser/order/list', data).then(res => res.data);
};

export interface IOrderDetail {
    id: number,
    title: string,
    money: number,
    process_id: number,
    process_title?: string,
    attach_files: number[],
    attach_files_info: Attachment[]
}

export interface IOrderDetailStep {
    id: number,
    order_process_step_id: number,
    order_id: number,
    process_id: number,
    process_step_id: number,
    sort: number,
    adviser_id: number,
    status: number,
    interrupt_at?: string,
    time_consuming?: number,
    time_consuming_text?: string,
    execute_time?: string,
    lat?: number,
    lng?: number,
    address?: string,
    desc?: string,
    attach_files: number[],
    attach_files_info: Attachment[]
    can_begin_execute: boolean,
    can_end_execute: boolean,
    is_current_step: boolean,
    execute_begin_at: string,
    status_text: string,
    order_process_step: {
        id: number,
        order_id: number,
        process_id: number,
        process_step_id: number,
        process_step_title: string,
        process_step_desc?: string,
        process_step_commission_percent: number,
        process_step_is_location: boolean,
        process_step_sort: number,
        process_step_attach_files: number[],
        process_step_attach_files_info: Attachment[],
        status: number,
        status_text: string,
        predict_execute_time?: string,
        is_overtime?: boolean,
        process_step_is_need_upload?: boolean,
    },
}

export interface IOrderDetailRes {
    detail: IOrderDetail,
    order_process_step_detail_list: IOrderDetailStep[]
}

/**
 * 获取业务详情
 * @returns 
 */
export const getOrderDetail = (data: { id: number }): Promise<CommonResponse<IOrderDetailRes>> => {
    return axiosInstance.get('/adviser/order/detail', { params: data }).then(res => res.data);
};


/**
 * 开始执行任务
 * @param data 
 * @returns 
 */
export const executeStart = (data: { order_process_step_detail_id: number }): Promise<CommonResponse> => {
    return axiosInstance.post('/adviser/order/begin_execute', data).then(res => res.data);
};

export interface ExecuteEndForm {
    order_process_step_detail_id: number,
    lat?: number,
    lng?: number,
    address?: string,
    desc: string,
    attach_files: number[]
}

/**
 * 结束执行任务
 * @param data 
 * @returns 
 */
export const executeEnd = (data: ExecuteEndForm): Promise<CommonResponse> => {
    return axiosInstance.post('/adviser/order/end_execute', data).then(res => res.data);
};