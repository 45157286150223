import { useEffect, useState } from 'react';
import { useDebounce } from 'ahooks';
import { useNavigate } from 'react-router-dom';

// antd
import { Button, Input, Table, Grid, Dropdown, Card, Empty, Pagination, Space, Menu, Modal, Tabs, List } from 'antd';
import { HistoryOutlined, DownloadOutlined, SearchOutlined } from '@ant-design/icons';


// interface
import { addFriend, getFriendsList, getFriendsSearchList, IAttachCertificationData, IFriends, IFriendsSearchListSearchForm } from '../../apis/friends';
import FriendsLogsListModal from './friendsLogs';

const { Column } = Table;
const { useBreakpoint } = Grid;

function FriendsSearchPage() {
    const screens = useBreakpoint();
    const navigate = useNavigate();

    // 搜索表单
    const [form, setForm] = useState<IFriendsSearchListSearchForm>({
        page: 1,
        size: 10,
        mobile: ''
    });

    const [isNull, setIsNull] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(IFriends)[]>([]);
    const [mListData, setMListData] = useState<(IFriends)[]>([]);
    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: IFriendsSearchListSearchForm) => {
        let _form = newForm ?? form;
        
        if (_form.mobile === '') {
            setMListData([]);
            setListData([])
            return;
        }
        setLoading(true);
        try {
            let res = await getFriendsSearchList(_form);
            if (res.data.total === 0) {
                setIsNull(true);
            } else {
                setIsNull(false);
            }
            setTotal(res.data.total);
            setMListData(res.data.list);
            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((_form.page! - 1) * _form.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    // 关键词搜索
    const handleKeywordsChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            mobile: value.target.value
        };
        setForm(newForm);
    }

    // 记录弹窗
    const [invitationLogsModal, setInvitationLogsModal] = useState<{
        isModalVisible: boolean,
    }>();
    const onInvitationLogClose = () => {
        loadData();
        setInvitationLogsModal({
            ...invitationLogsModal!,
            isModalVisible: false
        })
    };
    const toInvitationLog = () => {
        if (screens.xs) {
            navigate('/friends/logs');
        } else {
            setInvitationLogsModal({
                isModalVisible: true,
            })
        }
    }

    const toAdd = async (record: IFriends) => {
        try {
            await addFriend({ adviser_id: record.id });
            loadData();
        } catch (e) {
            console.error(e);
        }
    }

    // 显示附件弹窗
    const [attachmentModalForm, setAttachmentModalForm] = useState<{
        isModalVisible: boolean,
        data: { value: IAttachCertificationData[] }
    }>();
    const showAttachment = (value: IAttachCertificationData[], e?: any) => {
        e?.stopPropagation();
        setAttachmentModalForm({
            isModalVisible: true,
            data: { value }
        })
    }

    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            <div className='search'>
                <Input
                    className="search__item"
                    value={form.mobile}
                    onChange={handleKeywordsChange}
                    placeholder="手机号搜索"
                />
                <Button
                    className="search__button"
                    type='primary'
                    icon={<SearchOutlined />}
                    loading={loading}
                    onClick={() => loadData()}
                >
                    搜索
                </Button>


                <Button
                    type="primary"
                    className={screens.xs ? "search__button" : "search__last-button"}
                    icon={<HistoryOutlined />}
                    onClick={toInvitationLog}
                >
                    操作记录
                </Button>
            </div>
            {
                screens.xs
                    ? <div>
                        {
                            mListData.length > 0 ? mListData.map(item =>
                                <Dropdown
                                    trigger={['click']}
                                    menu={{
                                        items: [
                                            {
                                                key: 'add',
                                                disabled: item.is_friend,
                                                label: (
                                                    <div>{item.is_friend ? '已经添加' : '添加'}</div>
                                                ),
                                                onClick: () => toAdd(item),
                                            },
                                        ]
                                    }}>
                                    <Card className='m-card'>
                                        <div className='m-card_item'>
                                            <div>姓名：</div>
                                            <div>{item.name}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>联系方式：</div>
                                            <div>{item.mobile ?? '无'}</div>
                                        </div>
                                    </Card>
                                </Dropdown>
                            )
                                : <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={isNull ? "并未找到该手机的注册信息，请确认此手机是否已经注册！" : '请在上方搜索栏键入手机号搜索'}
                                ></Empty>

                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                            simple
                        />
                    </div>
                    : <Table
                        dataSource={listData}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        locale={{
                            emptyText: (
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={isNull ? "并未找到该手机的注册信息，请确认此手机是否已经注册！" : '请在上方搜索栏键入手机号搜索'}
                                ></Empty>
                            )
                        }}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column title="姓名" dataIndex="name" key="name" />
                        <Column title="联系方式" dataIndex="mobile" key="mobile" />
                        <Column
                            title="操作"
                            key="action"
                            fixed="right"
                            render={(_: any, record: IFriends) => (
                                <Space>
                                    <Dropdown.Button
                                        trigger={['click']}
                                        size='small'
                                        type="primary"
                                        // loading={}
                                        overlay={
                                            <Menu
                                                items={[
                                                    {
                                                        key: 'add',
                                                        disabled: record.is_friend,
                                                        label: (
                                                            <div>{record.is_friend ? '已经添加' : '添加'}</div>
                                                        ),
                                                        onClick: () => toAdd(record),
                                                    },
                                                ]}
                                            />
                                        }>
                                        操作
                                    </Dropdown.Button>
                                </Space>
                            )}
                        />
                    </Table>
            }

            {/* 记录 */}
            {
                invitationLogsModal ? <Modal
                    maskClosable={false}
                    visible={invitationLogsModal.isModalVisible}
                    onCancel={onInvitationLogClose}
                    width={1000}
                    footer={null}
                >
                    <FriendsLogsListModal></FriendsLogsListModal>
                </Modal> : null
            }
        </div>
    );
}

export default FriendsSearchPage;