import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalCountDownSec } from '../../utils/countdown';

// apis
import { changePassword, codeType, IResetPasswordForm, sendVCode } from '../../apis/user';

// antd
import { Button, Form, message, Space, Input, Modal, Row, Col } from 'antd';


function ChangePasswordFormPage() {
    // 路由
    const navigate = useNavigate();

    const [form] = Form.useForm<IResetPasswordForm>();

    // 获取用户信息
    let user = localStorage.getItem('user');
    if (!user) {
        navigate('/', { replace: true });
    } else {
        const userInfo = JSON.parse(user);
        form.setFieldValue('mobile', userInfo.mobile);
    }

    // 发送验证码
    const [countdown, startCountdown] = useGlobalCountDownSec(60, 'getChangePasswordCode');
    const sendCode = async () => {
        let mobile = form.getFieldValue('mobile');
        if (mobile) {
            startCountdown();
            // 请求
            let res = await sendVCode({ mobile, type: codeType.password });
            message.success(res.msg);
        } else {
            message.warning('未获取到手机号');
        }
    };


    // 修改密码
    const [submitLoading, setSubmitLoading] = useState(false);
    const submit = async () => {
        await form.validateFields();

        let rawForm = form.getFieldsValue(true);
        try {
            setSubmitLoading(true);
            let res = await changePassword(rawForm);
            message.success(res.msg);
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            navigate("/login", { replace: true });
            Modal.info({
                title: '提示',
                content: '修改密码后需要重新登录',
            });
        } catch (e) {
            console.error(e);
        } finally {
            setSubmitLoading(false);
        }

    }

    return (
        <div className='menu inner-page'>
            <div className='menu__title'>
                <div>修改密码</div>
            </div>

            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Row>
                    <Col sm={24} md={11}>
                        <Space style={{ display: 'flex' }}>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) => prevValues.mobile !== curValues.mobile}
                            >
                                {({ getFieldValue }) => {
                                    let mobile: string = getFieldValue('mobile');
                                    let maskMobile = mobile ? mobile.substring(0, 3) + '****' + mobile.substring(7) : undefined;
                                    return (
                                        <Form.Item
                                            label="手机号"
                                            rules={[{ required: true, message: '请输入原本的手机号' }]}
                                            help="如果长时间未收到验证码，请检查是否将运营商拉黑"
                                        >
                                            <Input readOnly value={maskMobile} />

                                        </Form.Item>
                                    );
                                }}
                            </Form.Item>

                            <Form.Item label=" ">
                                <Button
                                    loading={countdown > 0}
                                    onClick={sendCode}
                                >{countdown > 0 ? `${countdown}s` : '获取验证码'}</Button>
                            </Form.Item>
                        </Space>

                        <Form.Item
                            label="验证码"
                            name="code"
                            rules={[{ required: true, message: '请输入验证码' }]}
                        >
                            <Input autoComplete='new-password' placeholder="请输入验证码" maxLength={11} />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            label="新密码"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入新密码'
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || value.length >= 6) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('密码长度不得小于6位'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password maxLength={20} />
                        </Form.Item>

                        <Form.Item
                            name="password_confirmation"
                            label="再次输入密码"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: '请再次输入密码',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('两次输入的密码不同'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Space>
                                <Button type='primary' onClick={submit} loading={submitLoading}>提交表单</Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div >
    );
}


export default ChangePasswordFormPage;