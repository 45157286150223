import { axiosInstance, CommonResponse } from ".";
import { Attachment } from "./attachment";
import { IStaffData, Pager } from "./common";

export interface IAttachCertificationData {
    name: string,
    value: number,
    files_info: Attachment[]
}

export interface IFriends extends IStaffData {
    name_first_letter: string,
    mobile?: string,
    is_friend?: boolean,
    avg_appraise?: number,
    cooperation_num?: number,
    attach_certification_data_info?: IAttachCertificationData[],
}

export interface IFriendsList {
    list: IFriends[],
    total: number
}

export interface IFriendsListSearchForm extends Pager {
    keywords?: string
}

/**
 * 好友管理列表
 * @returns 
 */
export const getFriendsList = (data: IFriendsListSearchForm): Promise<CommonResponse<IFriendsList>> => {
    return axiosInstance.post('/adviser/friend/list', data).then(res => res.data);
};



export interface IFriendsSearchListSearchForm extends Pager {
    mobile: string
}

/**
 * 搜索好友列表
 * @returns 
 */
export const getFriendsSearchList = (data: IFriendsSearchListSearchForm): Promise<CommonResponse<IFriendsList>> => {
    return axiosInstance.post('/adviser/friend/search', data).then(res => res.data);
};

/**
 * 添加好友
 * @param data 
 * @returns 
 */
export const addFriend = (data: { adviser_id: number }): Promise<CommonResponse> => {
    return axiosInstance.post('/adviser/friend/add', data).then(res => res.data);
};

/**
 * 好友审核
 * @returns 
 */
export const operateFriend = (data: { id: number, status: 20 | 30 }): Promise<CommonResponse> => {
    return axiosInstance.post('/adviser/friend/operate', data).then(res => res.data);
};

/**
 * 删除好友
 * @returns 
 */
export const toDeleteFriend = (data: { friend_id: number }): Promise<CommonResponse> => {
    return axiosInstance.post('/adviser/friend/del', data).then(res => res.data);
};

export interface IFriendsLogs {
    id: number,
    friend_id: number,
    status: number,
    operate_at: string,
    name: string,
    mobile: string,
    avg_appraise: number,
    cooperation_num: number,
    attach_certification_data_info?: IAttachCertificationData[],
    status_text: string
}

export interface IFriendsLogsList {
    list: IFriendsLogs[],
    total: number
}

/**
 * 添加好友操作记录
 * @returns 
 */
export const getFriendsLogsList = (data: IFriendsListSearchForm): Promise<CommonResponse<IFriendsLogsList>> => {
    return axiosInstance.post('/adviser/friend_apply/list', data).then(res => res.data);
};


