import React, { useEffect, useState } from 'react';
import { useDebounce } from 'ahooks';
import { useNavigate } from 'react-router-dom';

// interface
import { getInvitationList, IInvitation, IInvitationListSearchForm } from '../../apis/invitation';

// antd
import { Space, Table, Dropdown, Menu, Button, Input, Grid, Pagination, Empty, Card, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import BottomNavigatorBar from '../../components/bottomNavigator/bottomNavigator';
import InvitationStepFormModal from './invitationStep';
import InvitationLogsListModal from './invitationLogs';
import InvitedLogsListModal from './invitedLogs';

const { Column } = Table;
const { useBreakpoint } = Grid;

function InvitationListPage() {
    const navigate = useNavigate();
    const screens = useBreakpoint();

    // 搜索表单
    const [form, setForm] = useState<IInvitationListSearchForm>({
        page: 1,
        size: 10,
    });

    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(IInvitation)[]>([]);
    const [mListData, setMListData] = useState<(IInvitation)[]>([]);
    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: IInvitationListSearchForm) => {
        let _form = newForm ?? form;
        setLoading(true);
        try {
            let res = await getInvitationList(_form);
            setTotal(res.data.total);
            setMListData(res.data.list);
            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((_form.page! - 1) * _form.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    const resetData = () => {
        let newForm: IInvitationListSearchForm = {
            page: 1,
            size: 10,
        }
        setForm(newForm);
        loadData(newForm);
    }

    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(form.keywords, { wait: 500 });
    useEffect(() => {
        loadData();
    }, [keywordsDebouncedValue]);
    const handleKeywordsChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            keywords: value.target.value
        };
        setForm(newForm);
    }

    // 邀请弹窗
    const [toAddEditModal, setAddEditModal] = useState<{
        isModalVisible: boolean,
        data?: { id: number }
    }>();
    const onAddEditClose = () => {
        loadData();
        setAddEditModal({
            ...toAddEditModal!,
            isModalVisible: false
        })
    };
    const toDetail = (id: number) => {
        if (screens.xs) {
            navigate('/invitation/step?id=' + id);
        } else {
            setAddEditModal({
                isModalVisible: true,
                data: { id: id }
            })
        }
    }

    // 邀请弹窗
    const [invitationLogsModal, setInvitationLogsModal] = useState<{
        isModalVisible: boolean,
    }>();
    const onInvitationLogClose = () => {
        loadData();
        setInvitationLogsModal({
            ...invitationLogsModal!,
            isModalVisible: false
        })
    };
    const toInvitationLog = () => {
        if (screens.xs) {
            navigate('/invitation/invitationLogs');
        } else {
            setInvitationLogsModal({
                isModalVisible: true,
            })
        }
    }

    // 被邀请弹窗
    const [invitedLogsModal, setInvitedLogsModal] = useState<{
        isModalVisible: boolean,
    }>();
    const onInvitedLogClose = () => {
        loadData();
        setInvitedLogsModal({
            ...invitedLogsModal!,
            isModalVisible: false
        })
    };
    const toInvitedLog = () => {
        if (screens.xs) {
            navigate('/invitation/invitedLogs');
        } else {
            setInvitedLogsModal({
                isModalVisible: true,
            })
        }
    }

    return (
        <div className='address inner-page'>
            <div className='search'>
                <Input
                    className="search__item"
                    value={form.keywords}
                    onChange={handleKeywordsChange}
                    placeholder="关键词搜索"
                />

                <Button
                    className="search__button"
                    icon={<CloseOutlined />}
                    loading={loading}
                    onClick={() => resetData()}
                >
                    重置筛选
                </Button>

                <div className={screens.xs ? "search__button" : "search__last-button"} style={{ marginTop: 10 }}>
                    <Button
                        type="primary"
                        style={{ marginRight: 10 }}
                        onClick={toInvitedLog}
                    >
                        被邀请记录
                    </Button>

                    <Button
                        style={{ marginRight: 10 }}
                        type="primary"
                        onClick={toInvitationLog}
                    >
                        邀请记录
                    </Button>
                </div>
            </div>
            {
                screens.xs
                    ? <div>
                        {
                            mListData.length > 0 ? mListData.map(item =>
                                <Card className='m-card' onClick={() => toDetail(item.id)}>
                                    <div className='m-card_item'>
                                        <div>订单名称：</div>
                                        <div>{item.order_title}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>流程：</div>
                                        <div>{item.order_process_title}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>总金额：</div>
                                        <div>{item.order_money}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>组团情况：</div>
                                        <div>{item.status_text}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>组团结束时间：</div>
                                        <div>{item.end_time}</div>
                                    </div>
                                </Card>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>

                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                            simple
                        />
                    </div>
                    : <Table
                        dataSource={listData}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column title="订单名称" dataIndex="order_title" key="order_title" />
                        <Column title="流程" dataIndex="order_process_title" key="order_process_title" />
                        <Column title="总金额" dataIndex="order_money" key="order_money" />
                        <Column title="组团情况" dataIndex="status_text" key="status_text" />
                        <Column title="组团结束时间" dataIndex="end_time" key="end_time" />
                        <Column
                            title="操作"
                            key="action"
                            fixed="right"
                            render={(_: any, record: IInvitation) => (
                                <Space>
                                    <Dropdown.Button
                                        trigger={['click']}
                                        size='small'
                                        type="primary"
                                        // loading={}
                                        overlay={
                                            <Menu
                                                items={[
                                                    {
                                                        key: 'detail',
                                                        disabled: !record.can_invite,
                                                        label: (
                                                            <div>邀请</div>
                                                        ),
                                                        onClick: () => toDetail(record.id),
                                                    },
                                                ]}
                                            />
                                        }>
                                        操作
                                    </Dropdown.Button>
                                </Space>
                            )}
                        />
                    </Table>
            }

            {screens.xs ? <BottomNavigatorBar current={1} /> : null}

            {/* 添加编辑弹窗 */}
            {
                toAddEditModal ? <Modal
                    maskClosable={false}
                    visible={toAddEditModal.isModalVisible}
                    onCancel={onAddEditClose}
                    width={1000}
                    footer={null}
                >
                    <InvitationStepFormModal data={toAddEditModal.data}></InvitationStepFormModal>
                </Modal> : null
            }

            {/* 邀请记录 */}
            {
                invitationLogsModal ? <Modal
                    maskClosable={false}
                    visible={invitationLogsModal.isModalVisible}
                    onCancel={onInvitationLogClose}
                    width={1000}
                    footer={null}
                >
                    <InvitationLogsListModal></InvitationLogsListModal>
                </Modal> : null
            }

            {/* 被邀请记录 */}
            {
                invitedLogsModal ? <Modal
                    maskClosable={false}
                    visible={invitedLogsModal.isModalVisible}
                    onCancel={onInvitedLogClose}
                    width={1000}
                    footer={null}
                >
                    <InvitedLogsListModal></InvitedLogsListModal>
                </Modal> : null
            }
        </div>
    );
}

export default InvitationListPage;