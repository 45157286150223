import React from 'react';

import { Avatar, Image } from 'antd';

import AvatarImg from '@assets/icons/default_avatar.svg';
import { getOssImageThumbUrl } from '../../utils/oss';

/**
 * 头像通用组件（oss缩略图优化）
 * @returns 
 */
export function AvatarImage({ url, width = 40, height = 40, style = { objectFit: "cover", borderRadius: 10 } }: { url?: string, width?: number, height?: number, style?: React.CSSProperties }) {
    return <Image
        style={style}
        width={width}
        height={height}
        src={url ? getOssImageThumbUrl(url) : AvatarImg}
        fallback={AvatarImg}
        preview={{
            src: url,
        }}
    />;
}

export function AvatarListItem({ url, style }: { url?: string, style?: React.CSSProperties }) {
    return <Avatar src={url ? getOssImageThumbUrl(url) : AvatarImg} alt="头像" style={style} />;
}