import { RouterProvider } from "react-router-dom";

import { router } from "./routes";

// style
import './index.css';



function RoutesLayout() {

    return (
        <div id="main-box">
            <RouterProvider router={router} />
        </div>
    );
}

export default RoutesLayout;