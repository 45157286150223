import { useEffect, useState } from 'react';

// apis
import { getSettlementAppraiseDetail, ISettlementDetail, ISettlementDetailStep } from '../../apis/settlement';

// antd
import { Card, Col, Collapse, Descriptions, Modal, Rate, Row, Table, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';


const { Column } = Table;

class AppraiseType {
    /**
     * 统一评价
     */
    static UNIFICATION = 1;
    /**
     * 分步骤评价
     */
    static SEPARATION = 2;
}

const gridStyle: React.CSSProperties = {
    width: '25%',
    textAlign: 'center',
};

function SettlementDetailPage() {
    const { id } = useParams();
    const navigate = useNavigate();

    // 获取用户信息
    const [detail, setDetail] = useState<ISettlementDetail>();
    const [stepList, setStepList] = useState<ISettlementDetailStep[]>();
    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        getSettlementAppraiseDetail({ order_id: Number(id) }).then(res => {
            setDetail(res.data.detail);
            setStepList(res.data.order_process_step_detail_list);
        });
    }

    return (
        <div className='menu inner-page'>
            <div className='menu__title'>
                <div>项目评价详情</div>
            </div>
            {
                detail && detail.appraise_type === AppraiseType.SEPARATION
                    ? <Row>
                        {
                            stepList?.map(step => (
                                <Col>
                                    <div style={{ margin: 10 }}>
                                        <Card title={`步骤：${step.order_process_step.process_step_title}`} style={{ minWidth: 250 }}>
                                            <Rate disabled value={step.order_process_step.score} />
                                            <p>{step.order_process_step.appraise}</p>
                                        </Card>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                    : null
            }
            <Tabs
                defaultActiveKey="1"
                items={[
                    {
                        label: `平台评分`,
                        key: '1',
                        children: (
                            <Table
                                dataSource={stepList}
                                size='small'
                            >
                                <Column title="步骤名称" dataIndex={['order_process_step', 'process_step_title']} key="process_step_title" />
                                <Column title="评分" dataIndex="attach_certification_data_info" key="attach_certification_data_info"
                                    render={(value: ISettlementDetailStep | null) => (
                                        value ? <Rate disabled value={value.order_process_step.score} /> : '无'
                                    )} />
                                <Column title="评价" dataIndex={['order_process_step', 'appraise']} key="appraise" />
                            </Table>
                        ),
                    },
                    {
                        label: `企业评分`,
                        key: '2',
                        children: (
                            <Table
                                dataSource={stepList}
                                size='small'
                            >
                                <Column title="步骤名称" dataIndex={['order_process_step', 'process_step_title']} key="process_step_title" />
                                <Column title="评分" dataIndex="attach_certification_data_info" key="attach_certification_data_info"
                                    render={(value: ISettlementDetailStep | null) => (
                                        value ? <Rate disabled value={value.order_process_step.company_score} /> : '无'
                                    )} />
                                <Column title="评价" dataIndex={['order_process_step', 'company_appraise']} key="appraise" />
                            </Table>
                        ),
                    },
                ]}
            />
        </div>
    );
}


export default SettlementDetailPage;