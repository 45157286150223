import { axiosInstance, CommonResponse } from ".";
import { Attachment } from "./attachment";
import { IStaffData, Pager } from "./common";

export interface IInvitation {
    id: number,
    order_id: number,
    order_title: string,
    order_process_title: string,
    order_money: number,
    end_time: string,
    status: number,
    status_text: string,
    can_invite: boolean,
}

export interface IInvitationList {
    list: IInvitation[],
    total: number
}

export interface IInvitationListSearchForm extends Pager {
    keywords?: string
}

/**
 * 邀请管理列表
 * @returns 
 */
export const getInvitationList = (data: IInvitationListSearchForm): Promise<CommonResponse<IInvitationList>> => {
    return axiosInstance.post('/adviser/order_group/list', data).then(res => res.data);
};

export interface IInvitationDetailListItem {
    id: number,
    order_id: number,
    order_group_id: number,
    process_id: number,
    process_step_title: string,
    process_step_sort: number,
    process_step_desc: string,
    money: number,
    adviser_list: {
        id: number,
        name: string,
        money: number
    }[],
    remain_assign_money: number,
    can_group_head_join: boolean
}

export interface IInvitationDetail {
    id: number,
    order_title: string,
    order_money: number
}

export interface IInvitationDetailList {
    detail: IInvitationDetail,
    list: IInvitationDetailListItem[],
    total: number
}

export interface IInvitationDetailSearchForm extends Pager {
    order_group_id: number
}

/**
 * 邀请步骤详情
 * @returns 
 */
export const getInvitationDetail = (data: IInvitationDetailSearchForm): Promise<CommonResponse<IInvitationDetailList>> => {
    return axiosInstance.post('/adviser/order_group/process_step', data).then(res => res.data);
};

export interface IToInviteForm {
    id: number,
    adviser_list: {
        id: number,
        name: string,
        money: number
    }[]
}


/**
 * 邀请好友
 * @returns 
 */
export const toInvite = (data: IToInviteForm): Promise<CommonResponse> => {
    return axiosInstance.post('/adviser/order_group/invite', data).then(res => res.data);
};

/**
 * 加入步骤
 * @returns 
 */
export const toJoinIn = (data: { id: number, money: number }): Promise<CommonResponse> => {
    return axiosInstance.post('/adviser/order_group/group_head_join', data).then(res => res.data);
};


/**
 * 邀请审核
 * @returns 
 */
export const operateInvitation = (data: { id: number, status: 20 | 30 }): Promise<CommonResponse> => {
    return axiosInstance.post('/adviser/order_group/confirm_invite', data).then(res => res.data);
};

export interface IInvitationLogsListItem {
    id: number,
    order_id: number,
    order_group_id: number,
    order_group_process_step_id: number,
    process_id: number,
    adviser_id: number,
    status: number,
    money: number,
    invite_at: string,
    confirm_at?: string,
    status_text: string,
    order_group: {
        id: number,
        order_title: string,
        order_money: number
    },
    process: {
        id: number,
        title: string
    },
    order_group_process_step: {
        id: number,
        process_step_title: string
    },
    adviser?: IStaffData

    group_head_id?: 1,
    can_confirm?: true,
    group_head?: IStaffData
}

export interface IInvitationLogsList {
    list: IInvitationLogsListItem[],
    total: number
}

export interface IInvitationLogsSearchForm extends Pager {
    status?: number
}

/**
 * 邀请记录
 * @returns 
 */
export const getInvitedLogs = (data: IInvitationLogsSearchForm): Promise<CommonResponse<IInvitationLogsList>> => {
    return axiosInstance.post('/adviser/order_group/my_invite', data).then(res => res.data);
};

/**
 * 被邀请记录
 * @returns 
 */
export const getInvitationLogs = (data: IInvitationLogsSearchForm): Promise<CommonResponse<IInvitationLogsList>> => {
    return axiosInstance.post('/adviser/order_group/invite_log', data).then(res => res.data);
};
