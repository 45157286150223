// antd
import { Button, Result } from "antd";

// assets
import notFoundImg from '@assets/404.svg';

export function NotFoundPage() {
    return (
        <Result
            icon={<img style={{ width: '600px' }} src={notFoundImg} alt="找不到页面" />}
            title="404"
            subTitle="您要访问的页面不存在"
            extra={<Button type="primary" href="/">返回首页</Button>}
        />
    );
}