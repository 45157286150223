import { useEffect, useState } from 'react';

// interface
import { getMessageList, IMessage, IMessageListSearchForm, setAllMessageRead, setMessageRead } from '../../apis/message';

// antd
import { Space, Table, Dropdown, Menu, Button, Grid, Pagination, Empty, Card, Modal, Select, message, Divider, Row, Col } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button as ButtonMobile } from 'antd-mobile';

import { getDictList, IDictData } from '../../apis/common';
import { operateFriend } from '../../apis/friends';
import { operateInvitation } from '../../apis/invitation';

const { Column } = Table;
const { useBreakpoint } = Grid;
const { Option } = Select;
const { confirm } = Modal;

function MessageListPage() {
    const screens = useBreakpoint();

    // 搜索表单
    const [form, setForm] = useState<IMessageListSearchForm>({
        page: 1,
        size: 10,
    });

    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(IMessage)[]>([]);
    const [mListData, setMListData] = useState<(IMessage)[]>([]);
    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: IMessageListSearchForm) => {
        let _form = newForm ?? form;
        setLoading(true);
        try {
            let res = await getMessageList(_form);
            setTotal(res.data.total);
            setMListData(res.data.list);
            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((_form.page! - 1) * _form.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    const resetData = () => {
        let newForm: IMessageListSearchForm = {
            page: 1,
            size: 10,
        }
        setForm(newForm);
        loadData(newForm);
    }

    // 消息类型
    const [notificationType, setNotificationType] = useState<IDictData>();
    const handleNotificationTypeChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                source: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    useEffect(() => {
        getDictList(['notification_type']).then(res => {
            setNotificationType(res.data.notification_type);
        });
    }, []);

    const toDetail = (record: IMessage) => {
        // 已读
        if (!record.is_read) {
            setMessageRead({ id: record.id }).then(res => {
                loadData();
            });
        }

        const showNormalModal = () => {
            Modal.info({
                title: record.title,
                content:
                    <p style={{ whiteSpace: 'pre-wrap' }}>{record.content}</p>,
                onOk() { },
            });
        }

        if (screens.xs) {
            showNormalModal();
            return;
        }


        if (record.is_operate) {
            showNormalModal();
        } else {
            switch (record.type) {
                case 100:
                    // 项目邀请
                    let confirmModal1 = confirm({
                        title: record.title,
                        icon: <ExclamationCircleOutlined />,
                        closable: true,
                        content: <p style={{ whiteSpace: 'pre-wrap' }}>{record.content}</p>,
                        footer: <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => {
                                operateInvitation({ id: record.entity_id, status: 20 })
                                    .then(res => {
                                        message.success(res.msg);
                                        loadData();
                                    }).finally(() => {
                                        confirmModal1.destroy();
                                    })
                            }}>拒绝</Button>,
                            <Button type='primary' onClick={() => {
                                operateInvitation({ id: record.entity_id, status: 30 })
                                    .then(res => {
                                        message.success(res.msg);
                                        loadData();
                                    }).finally(() => {
                                        confirmModal1.destroy();
                                    })
                            }}>同意</Button>,
                        </div>,
                    });
                    break;
                case 200:
                    // 好友添加
                    let confirmModal2 = confirm({
                        title: record.title,
                        icon: <ExclamationCircleOutlined />,
                        closable: true,
                        content: <p style={{ whiteSpace: 'pre-wrap' }}>{record.content}</p>,
                        footer: <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => {
                                operateFriend({ id: record.entity_id, status: 20 })
                                    .then(res => {
                                        message.success(res.msg);
                                        loadData();
                                    }).finally(() => {
                                        confirmModal2.destroy();
                                    })
                            }}>拒绝</Button>,
                            <Button type='primary' onClick={() => {
                                operateFriend({ id: record.entity_id, status: 30 })
                                    .then(res => {
                                        message.success(res.msg);
                                        loadData();
                                    }).finally(() => {
                                        confirmModal2.destroy();
                                    })
                            }}>同意</Button>,
                        </div>,
                    });
                    break;

                default:
                    showNormalModal();
                    break;
            }
        }

    }

    // 全部已读
    const onReadAllHandle = () => {
        confirm({
            title: '提示',
            content: "确定将所有消息标记为已读吗？",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                return new Promise((resolve, reject) => {
                    setAllMessageRead().then((res) => {
                        loadData();
                        resolve(res);
                    }).catch(reject);
                }).catch(() => console.log('Oops errors!'));
            },
        });
    }

    const toAcceptanceInvitation = async (id: number, e?: any) => {
        e?.stopPropagation();

        setLoading(true);
        try {
            let res = await operateInvitation({ id: id, status: 30 })
            message.success(res.msg);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }

        await loadData();
    }

    const toRefusalInvitation = async (id: number, e?: any) => {
        e?.stopPropagation();

        setLoading(true);
        try {
            let res = await operateInvitation({ id: id, status: 20 })
            message.success(res.msg);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }

        await loadData();
    }

    const toAcceptanceFriend = async (id: number, e?: any) => {
        e?.stopPropagation();

        setLoading(true);
        try {
            let res = await operateFriend({ id: id, status: 30 })
            message.success(res.msg);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }

        await loadData();
    }

    const toRefusalFriend = async (id: number, e?: any) => {
        e?.stopPropagation();

        setLoading(true);
        try {
            let res = await operateFriend({ id: id, status: 20 })
            message.success(res.msg);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }

        await loadData();
    }

    return (
        <div className='address inner-page'>
            <div className='search'>
                <Select
                    allowClear
                    className="search__item"
                    showArrow
                    placeholder="信息来源"
                    value={form.type}
                    onChange={handleNotificationTypeChange}
                >
                    {notificationType?.options.map(option => (
                        <Option key={option.value} value={option.value}>{option.name}</Option>
                    ))}
                </Select>

                <Button
                    className="search__button"
                    icon={<CloseOutlined />}
                    loading={loading}
                    onClick={() => resetData()}
                >
                    重置筛选
                </Button>

                <Button
                    className={screens.xs ? "search__button" : "search__last-button"}
                    type="primary"
                    onClick={onReadAllHandle}
                >
                    全部标记为已读
                </Button>
            </div>
            {
                screens.xs
                    ? <div>
                        {
                            mListData.length > 0 ? mListData.map(item =>
                                <Card className='m-card' onClick={() => toDetail(item)}>
                                    <div className='m-card_item'>
                                        <div>标题：</div>
                                        <div>{item.title}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>时间：</div>
                                        <div>{item.created_at}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>状态：</div>
                                        <div>{item.is_read ? '已读' : '未读'}</div>
                                    </div>
                                    {
                                        !item.is_operate && item.type === 100 &&
                                        <>
                                            <Divider></Divider>
                                            <Row gutter={10}>
                                                <Col span={12}>
                                                    <ButtonMobile
                                                        block
                                                        loading={loading}
                                                        color='primary'
                                                        onClick={(e) => toAcceptanceInvitation(item.id, e)}
                                                    >同意</ButtonMobile>
                                                </Col>
                                                <Col span={12}>
                                                    <ButtonMobile
                                                        block
                                                        loading={loading}
                                                        color='danger'
                                                        onClick={(e) => toRefusalInvitation(item.id, e)}
                                                    >拒绝</ButtonMobile>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {
                                        !item.is_operate && item.type === 200 &&
                                        <>
                                            <Divider></Divider>
                                            <Row gutter={10}>
                                                <Col span={12}>
                                                    <ButtonMobile
                                                        block
                                                        loading={loading}
                                                        color='primary'
                                                        onClick={(e) => toAcceptanceFriend(item.id, e)}
                                                    >同意</ButtonMobile>
                                                </Col>
                                                <Col span={12}>
                                                    <ButtonMobile
                                                        block
                                                        loading={loading}
                                                        color='danger'
                                                        onClick={(e) => toRefusalFriend(item.id, e)}
                                                    >拒绝</ButtonMobile>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </Card>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                            simple
                        />
                    </div>
                    : <Table
                        dataSource={listData}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column title="标题" dataIndex="title" key="title" />
                        <Column title="时间" dataIndex="created_at" key="created_at" />
                        <Column title="状态" dataIndex="is_read" key="is_read"
                            render={(value: any) => (
                                value ? '已读' : '未读'
                            )}
                        />
                        <Column
                            title="操作"
                            key="action"
                            fixed="right"
                            render={(_: any, record: IMessage) => (
                                <Space>
                                    <Dropdown.Button
                                        trigger={['click']}
                                        size='small'
                                        type="primary"
                                        // loading={}
                                        overlay={
                                            <Menu
                                                items={[
                                                    {
                                                        key: 'detail',
                                                        label: (
                                                            <div>查看</div>
                                                        ),
                                                        onClick: () => toDetail(record),
                                                    },
                                                ]}
                                            />
                                        }>
                                        操作
                                    </Dropdown.Button>
                                </Space>
                            )}
                        />
                    </Table>
            }
        </div>
    );
}

export default MessageListPage;