import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

// apis
import { IUserInfo } from '../../apis/user';
import { FileUpload, uploadFile } from '../../apis/common';
import { executeEnd, ExecuteEndForm } from "../../apis/order";

// antd
import { Button, Form, message, Input, DatePicker, UploadFile, Upload, Modal, Grid, Space } from 'antd';

import TextArea from 'antd/es/input/TextArea';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { UploadRequestOption } from "rc-upload/lib/interface";
import { Attachment } from '../../apis/attachment';
import { UploadOutlined } from '@ant-design/icons';

// map
import AMapLoader from '@amap/amap-jsapi-loader';
import "@amap/amap-jsapi-types";

const { useBreakpoint } = Grid;

// 地图组件
const MapComponent: React.FC<{ setMap: React.Dispatch<React.SetStateAction<AMap.Map | undefined>> }> = ({ setMap }) => {
    const screens = useBreakpoint();

    const [id, setId] = useState(`container${Math.random()}`);

    console.log(id);


    useEffect(() => {
        AMapLoader.load({
            key: "264e6012594f67ffb53b1964e294eb94",                     // 申请好的Web端开发者Key，首次调用 load 时必填
            version: "2.0",              // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins: ['AMap.Geolocation', 'AMap.Geocoder'],               // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((map: typeof AMap) => {
            setMap(() => {
                try {
                    console.log(id);
                    let newMap: AMap.Map = new map.Map(id, { //设置地图容器id
                        // viewMode: "3D",         //是否为3D地图模式
                        zoom: 17,                //初始化地图级别
                    })
                    return newMap;
                } catch (e) {
                    console.error(e);
                }
            });
        }).catch(e => {
            console.log(e);
        })
    }, []);

    return (
        <div
            id={id}
            className="map"
            style={{ height: '40vh', width: !screens.md ? '100%' : '50%' }}
        ></div>
    );
}

function OrderFinishFormPage() {
    const navigate = useNavigate();
    const [form] = Form.useForm<ExecuteEndForm>();

    const [search, _] = useSearchParams();
    const [isLocation, setIsLocation] = useState(false);
    const [isNeedUpload, setNeedUpload] = useState(false);
    useEffect(() => {
        setIsLocation(search.get('location') === 'true');
        setNeedUpload(search.get('attachment') === 'true');
    }, [search]);
    const { id } = useParams();
    useEffect(() => {
        form.setFieldsValue({
            order_process_step_detail_id: Number(id),
        })
    }, [id]);

    const [map, setMap] = useState<AMap.Map>();
    useEffect(() => {
        if (map) {
            AMap.plugin('AMap.Geolocation', () => {
                let geolocation = new AMap.Geolocation({
                    // 是否使用高精度定位，默认：true
                    enableHighAccuracy: true,
                    // 设置定位超时时间，默认：无穷大
                    // timeout: 10000,
                    // 定位按钮的停靠位置的偏移量
                    offset: [10, 20],
                    //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                    zoomToAccuracy: true,
                    //  定位按钮的排放位置,  RB表示右下
                    position: 'RB',
                    showButton: false
                });
                map.addControl(geolocation);
                const getPosition = () => {
                    const reconnect = () => {
                        message.error('获取定位失败，3秒后自动重试');
                        // setTimeout(() => {
                        //     getPosition();
                        // }, 3000);
                        form.setFieldsValue({
                            lat: 39.904179,
                            lng: 116.407387,
                            address: '测试地址'
                        });
                        map.setCenter([116.407387, 39.904179]);
                    };

                    const listener = (status: string, result: AMap.GeolocationResult) => {
                        console.log(status, result);
                        if (status == 'complete') {
                            form.setFieldsValue({
                                lat: result.position.lat,
                                lng: result.position.lng,
                            });
                            AMap.plugin('AMap.Geocoder', function () {
                                var geocoder = new AMap.Geocoder({});

                                geocoder.getAddress(
                                    new AMap.LngLat(result.position.lng, result.position.lat),
                                    function (status, result) {
                                        if (status === 'complete' && result.info === 'OK') {
                                            form.setFieldsValue({
                                                address: result.regeocode.formattedAddress
                                            })
                                        } else {
                                            reconnect();
                                        }
                                    })
                            });
                        } else {
                            reconnect();
                        }
                    };
                    geolocation.getCurrentPosition(listener);
                }
                getPosition();
            });
        }
    }, [map]);



    // 提交
    const [submitLoading, setSubmitLoading] = useState(false);
    const submit = async () => {
        await form.validateFields();

        if (isNeedUpload && attachments.length === 0) {
            message.error('请至少上传一个附件');
            return;
        }

        let rawForm = JSON.parse(JSON.stringify(form.getFieldsValue(true)));

        try {
            setSubmitLoading(true);
            let res = await executeEnd({
                ...rawForm,
                // 附件
                attach_files: attachments
                    .filter(file => file.response)
                    .map(file => {
                        let res: FileUpload | Attachment = file.response;
                        return (res as FileUpload).file_id || (res as Attachment).id;
                    })
            });
            message.success(res.msg);
            navigate(-1);
        } catch (e) {
            console.error(e);
        } finally {
            setSubmitLoading(false);
        }
    }

    // 上传附件
    const [attachments, setAttachments] = useState<UploadFile<any>[]>([]);
    const checkFileSize = (file: RcFile) => {
        if ((file.size / 1024 / 1024) > 50) {
            message.error(`${file.name} 文件超出限制大小`);
            return Upload.LIST_IGNORE;
        }

        return true;
    };
    const onFileChange = (info: UploadChangeParam<UploadFile<any>>) => {
        console.log(info.fileList);
        setAttachments(info.fileList);
    }
    const customRequest = (options: UploadRequestOption<any>) => {
        const { onSuccess, onError, file, onProgress } = options;
        uploadFile({ file: file as Blob, type: 'order' })
            .then(res => {
                onSuccess!(res.data);
            });
    }
    const onAttachmentDownload = (file: UploadFile<any>) => {
        console.log(file);
        let res: FileUpload | Attachment = file.response;
        window.open((res as FileUpload).full_link || (res as Attachment).attach_url_link, "_blank");
    }

    return (
        <div className='menu inner-page'>
            <div className='menu__title'>
                <div>个人信息</div>
            </div>

            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                {
                    isLocation
                        ? <>
                            <Form.Item>
                                <MapComponent setMap={setMap}></MapComponent>
                            </Form.Item>

                            <Form.Item
                                label="当前定位"
                                name='address'
                                rules={[{ required: true, message: '获取不到当前地址，正在重试' }]}
                            >
                                <Input readOnly />
                            </Form.Item>
                        </>
                        : null
                }


                <Form.Item
                    label="任务完成描述详情"
                    name='desc'
                    rules={[{ required: true, message: '请输入任务完成描述详情' }]}
                >
                    <TextArea rows={4} />
                </Form.Item>

                <Form.Item
                    label="附件"
                    required={isNeedUpload}
                >
                    <Upload
                        fileList={attachments}
                        beforeUpload={checkFileSize}
                        onChange={onFileChange}
                        customRequest={customRequest}
                        showUploadList={{ showDownloadIcon: true }}
                        onDownload={onAttachmentDownload}
                    >
                        <Button icon={<UploadOutlined />}>点击上传文件</Button>
                    </Upload>
                </Form.Item>

                <Form.Item>
                    <Space>
                        <Button type='primary' onClick={submit} loading={submitLoading}>提交表单</Button>
                    </Space>
                </Form.Item>
            </Form>
        </div >
    );
}


export default OrderFinishFormPage;