import { useState } from 'react';

// 获取全局倒计时差
export const getGlobalCountDownSec = (sec: number, key: string): number => {
    const nowTimestamp = new Date().getTime();
    const lastTimestamp = Number(localStorage.getItem(`timestamp-${key}`));
    const res = sec - ((nowTimestamp - lastTimestamp) / 1000);
    console.log(nowTimestamp, lastTimestamp, sec, ((nowTimestamp - lastTimestamp) / 1000));

    return res > 0 ? Math.round(res) : 0;
};

// 全局倒计时hook
export const useGlobalCountDownSec = (sec: number, key: string): [number, typeof startGlobalCount] => {
    // 倒计时初始化
    const [count, setCount] = useState(getGlobalCountDownSec(sec, key));

    // 计数器更新count
    setTimeout(() => {
        console.log(count);
        if (count > 0) {
            setCount(getGlobalCountDownSec(sec, key));
        }
    }, 1000);

    const startGlobalCount = () => {
        // 重置全局倒计时开始时间
        localStorage.setItem(`timestamp-${key}`, new Date().getTime().toString());
        // 重置count
        setCount(sec);
    };

    return [count, startGlobalCount];
}