import { Dayjs } from "dayjs";
import { axiosInstance, CommonResponse } from ".";
import { Attachment } from "./attachment";


// 10 注册 20 登录 30 修改密码 40 绑定 50 转移管理员 60 更换手机
type codeTypeItems = 10 | 20 | 30 | 40 | 50 | 60
export const codeType: {
    [key: string]: codeTypeItems,
} = {
    register: 10, // 注册
    login: 20, // 登录
    password: 30, // 修改密码
    bind: 40, // 绑定
    transfer: 50, // 转移管理员
    changeMobile: 60 // 更换手机
};

export interface ISendVCodeForm {
    mobile: string,
    type: codeTypeItems
}

/**
 * 发送验证码
 * @param data 
 * @returns 
 */
export const sendVCode = (data: ISendVCodeForm): Promise<CommonResponse> => {
    return axiosInstance.post('/api/shares/sms_send', data).then(res => res.data);
};

export const loginType = {
    code: 1, // 验证码
    password: 2, // 密码
};

export interface ILoginForm {
    login_type: number,
    mobile: string,
    password?: string
    code?: string,
}

export interface ILoginData {
    token: string,
    is_new: boolean
}

/**
 * @description: 用户登录
 * @params {ILoginForm} params
 * @return {Promise}
 */
export const login = (data: ILoginForm): Promise<CommonResponse<ILoginData>> => {
    return axiosInstance.post('/adviser/auth/login', data).then(res => res.data);
};

export interface IUserInfo {
    id?: number,
    name?: string,
    mobile?: string,
    status?: number,
    avatar_id?: number,
    idcard?: string,
    dept_name?: string,
    bank_no?: string,
    bank_name?: string,
    idcard_front_img?: number,
    idcard_back_img?: number,
    idcard_front_img_link?: string,
    idcard_back_img_link?: string,
    is_idcard_long_time?: boolean,
    idcard_begin_date?: string,
    idcard_expire_date?: string,
    avatar_link?: string,
    sex?: number,
    sex_text?: string,
    birthday?: string,
    status_text?: string

    qualification_desc?: string,
    qualification_attach_files?: number[],
    qualification_attach_files_info?: Attachment[]
}

export interface IUserInitData {
    adviser?: IUserInfo,
    is_applying: boolean,
    mobile: string
}

/**
 * @description: 获取用户初始化信息
 * @returns {Promise}
 */
export const getUserInitData = (): Promise<CommonResponse<IUserInitData>> => {
    return axiosInstance.get('/adviser/user/init_data').then(res => res.data);
};

export interface IRegisterForm {
    name: string,
    idcard: string,
    avatar_id?: number,
    dept_name?: string,
    company_name?: string
    is_idcard_long_time: boolean,
    idcard_begin_date: string,
    idcard_expire_date: string,
    province_id: number,
    city_id: number,
    area_id: number,
    street_id: number,
    address: string,
    idcard_front_img: number,
    idcard_back_img: number,
    attach_certification_data: {
        name: string,
        value: number,
        files: number[]
    }[],
}

/**
 * 注册
 * @param data 
 * @returns 
 */
export const register = (data: IRegisterForm): Promise<CommonResponse> => {
    return axiosInstance.post('/adviser/register/index', data).then(res => res.data);
};

export interface IResetPasswordForm {
    code: string,
    password: string,
    password_confirmation: string
}

/**
 * 修改密码
 * @param data 
 * @returns 
 */
export const changePassword = (data: IResetPasswordForm): Promise<CommonResponse> => {
    return axiosInstance.post('/adviser/user/set_password', data).then(res => res.data);
};

export interface IUserInfoData {
    adviser: IUserInfo,
}

/**
 * 业务员信息
 * @returns 
 */
export const getUserInfo = (): Promise<CommonResponse<IUserInfoData>> => {
    return axiosInstance.get('/adviser/user/profile').then(res => res.data);
};

export interface IUpdateUserInfoForm {
    name: string,
    avatar_id?: number,
    dept_name?: string,
    bank_no?: string,
    bank_name?: string,
    idcard_front_img?: number,
    idcard_back_img?: number,
    is_idcard_long_time?: boolean,
    idcard_begin_date?: string,
    idcard_expire_date?: string
    qualification_desc?: string,
    qualification_attach_files: number[],
    idcard_date?: Dayjs[],
    sex?: number,
    birthday?: string
}

export const updateUserInfo = (data: IUpdateUserInfoForm): Promise<CommonResponse> => {
    return axiosInstance.post('/adviser/user/edit_profile', data).then(res => res.data);
};