import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// antd
import { Grid, Modal, Table, Space, Dropdown, Menu, Pagination, Card, Empty, Form, message, InputNumber } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { getInvitationDetail, IInvitationDetail, IInvitationDetailListItem, IInvitationDetailSearchForm, toJoinIn } from '../../apis/invitation';
import InvitationStepAddStaffFormModal from './invitationStepAddStaff';

const { useBreakpoint } = Grid;
const { Column } = Table;
const { confirm } = Modal;


interface CollectionCreateFormProps {
    data?: { id: number },
}

function InvitationStepFormModal({
    data,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, _] = useSearchParams();

    // 搜索表单
    const [form, setForm] = useState<IInvitationDetailSearchForm>({
        order_group_id: data?.id ?? Number(searchParams.get('id')),
        page: 1,
        size: 10,
    });

    const [loading, setLoading] = useState(false);

    const [detail, setDetail] = useState<IInvitationDetail>();
    const [list, setList] = useState<IInvitationDetailListItem[]>([]);
    const [mListData, setMListData] = useState<(IInvitationDetailListItem)[]>([]);
    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: IInvitationDetailSearchForm) => {
        let _form = newForm ?? form;
        setLoading(true);
        try {
            let res = await getInvitationDetail(_form);
            setTotal(res.data.total);
            setDetail(res.data.detail);
            setMListData(res.data.list);
            setList((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((_form.page! - 1) * _form.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        resetData();
    }, [data, searchParams]);

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    const resetData = () => {
        let newForm: IInvitationDetailSearchForm = {
            order_group_id: data?.id ?? Number(searchParams.get('id')),
            page: 1,
            size: 10,
        }
        setForm(newForm);
        loadData(newForm);
    }

    const [toAddEditModal, setAddEditModal] = useState<{
        isModalVisible: boolean,
        data?: { id: number, remain_assign_money: number }
    }>();
    const onAddEditClose = () => {
        setAddEditModal({
            ...toAddEditModal!,
            isModalVisible: false
        })
    };
    const onAddEditFinish = () => {
        loadData();
        setAddEditModal({
            ...toAddEditModal!,
            isModalVisible: false
        })
    }
    const toAdd = (record: IInvitationDetailListItem) => {
        if (screens.xs) {
            navigate('/invitation/stepAdd?id=' + record.id + '&m=' + record.remain_assign_money);
        } else {
            setAddEditModal({
                isModalVisible: true,
                data: {
                    id: record.id,
                    remain_assign_money: record.remain_assign_money
                }
            })
        }
    }


    // 团长加入
    const [joinForm] = Form.useForm<{ money: number, id: number }>();
    const [isModalOpen, setIsModalOpen] = useState<{
        isShow: boolean,
        id: number,
        maxMoney: number
    }>({
        isShow: false,
        id: 0,
        maxMoney: 0
    });
    const toAddLeader = async () => {
        return new Promise((resolve, reject) => {
            let _form = joinForm.getFieldsValue(true);
            toJoinIn(_form).then(res => {
                resolve(res);
                message.success(res.msg);
                setIsModalOpen({
                    ...isModalOpen,
                    isShow: false,
                });
                loadData();
            }).catch(e => reject(e));
        }).catch(() => console.log('Oops errors!'));
    }

    return (
        <div className={'menu ' + (screens.xs ? 'inner-page mobile' : '')}>
            <div className='menu__title'>
                <div>{detail?.order_title}（项目总金额：{detail?.order_money}元）</div>
            </div>

            {
                screens.xs
                    ? <div>
                        {
                            mListData.length > 0 ? mListData.map(item =>
                                <Dropdown
                                    trigger={['click']}
                                    menu={{
                                        items: [
                                            {
                                                key: 'add',
                                                label: (
                                                    <div>添加步骤人员</div>
                                                ),
                                                onClick: () => toAdd(item),
                                            },
                                            {
                                                key: 'join',
                                                disabled: !item.can_group_head_join,
                                                label: (
                                                    <div>加入</div>
                                                ),
                                                onClick: () => setIsModalOpen({
                                                    isShow: true,
                                                    maxMoney: item.remain_assign_money,
                                                    id: item.id
                                                }),
                                            },
                                        ]
                                    }}>
                                    <Card className='m-card'>
                                        <div className='m-card_item'>
                                            <div>步骤名称：</div>
                                            <div>{item.process_step_title}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>步骤金额：</div>
                                            <div>{item.money}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>步骤描述：</div>
                                            <div>{item.process_step_desc}</div>
                                        </div>
                                        <div className='m-card_item'>
                                            <div>步骤人员：</div>
                                            <div>{item.adviser_list.map(item => item.name).join("，")}</div>
                                        </div>
                                    </Card>
                                </Dropdown>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>

                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                            simple
                        />
                    </div>
                    : <Table
                        dataSource={list}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column title="步骤名称" dataIndex="process_step_title" key="process_step_title" />
                        <Column title="步骤金额" dataIndex="money" key="money" />
                        <Column title="步骤描述" dataIndex="process_step_desc" key="process_step_desc" />
                        <Column title="步骤人员" dataIndex="adviser_list" key="adviser_list"
                            render={(value: any[]) => (
                                value.map(item => `${item.name}(${item.money})`).join("，")
                            )}
                        />
                        <Column
                            title="操作"
                            key="action"
                            fixed="right"
                            render={(_: any, record: IInvitationDetailListItem) => (
                                <Space>
                                    <Dropdown.Button
                                        trigger={['click']}
                                        size='small'
                                        type="primary"
                                        overlay={
                                            <Menu
                                                items={[
                                                    {
                                                        key: 'add',
                                                        label: (
                                                            <div>添加步骤人员</div>
                                                        ),
                                                        onClick: () => toAdd(record),
                                                    },
                                                    {
                                                        key: 'join',
                                                        disabled: !record.can_group_head_join,
                                                        label: (
                                                            <div>加入</div>
                                                        ),
                                                        onClick: () => setIsModalOpen({
                                                            isShow: true,
                                                            maxMoney: record.remain_assign_money,
                                                            id: record.id,
                                                        }),
                                                    },
                                                ]}
                                            />
                                        }>
                                        操作
                                    </Dropdown.Button>
                                </Space>
                            )}
                        />
                    </Table>
            }

            {/* 添加编辑弹窗 */}
            {
                toAddEditModal ? <Modal
                    maskClosable={false}
                    title="邀请步骤人员"
                    visible={toAddEditModal.isModalVisible}
                    onCancel={onAddEditClose}
                    footer={null}
                >
                    <InvitationStepAddStaffFormModal data={toAddEditModal.data} onFinish={onAddEditFinish}></InvitationStepAddStaffFormModal>
                </Modal> : null
            }

            <Modal destroyOnClose title="加入步骤分配金额" open={isModalOpen.isShow} onOk={toAddLeader} onCancel={() => setIsModalOpen({
                ...isModalOpen,
                isShow: false,
            })}>
                <Form
                    form={joinForm}
                    layout="vertical"
                    autoComplete="off"
                    initialValues={{
                        id: isModalOpen.id
                    }}
                >
                    <Form.Item
                        label={`分配金额`}
                        name='money'
                        rules={[{ required: true, message: '请分配金额' }]}
                        extra={`剩余可分配金额：${isModalOpen.maxMoney}元`}
                    >
                        <InputNumber min={0} max={isModalOpen.maxMoney} addonAfter="元" style={{ width: "100%" }} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default InvitationStepFormModal;