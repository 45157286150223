import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './index.css';
import RoutesLayout from './routes';

import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import { ConfigProvider } from 'antd';

import './assets/style/style.scss';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

window._AMapSecurityConfig = {
    // serviceHost: '您的代理服务器域名或地址/_AMapService',
    // 例如 ：serviceHost:'http://1.1.1.1:80/_AMapService',
    securityJsCode: '7fe26ec5d47a984719f2d1a7b7cae45a',
}

root.render(
    <ConfigProvider locale={zhCN}>
        <RoutesLayout />
    </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
