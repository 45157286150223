import React, { useEffect, useState } from 'react';

// apis
import { executeStart, getOrderDetail, IOrderDetail, IOrderDetailStep } from '../../apis/order';

// antd
import { Button, Card, Collapse, Descriptions, List, Modal } from 'antd';
import { DownloadOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';

import './detail.scss';
import dayjs from 'dayjs';

const { Panel } = Collapse;
const { confirm } = Modal;

function OrderDetailPage() {
    const { id } = useParams();
    const navigate = useNavigate();

    // 获取用户信息
    const [detail, setDetail] = useState<IOrderDetail>();
    const [stepList, setStepList] = useState<IOrderDetailStep[]>();
    const [defaultActiveKey, setDefaultActiveKey] = useState<string>();
    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        getOrderDetail({ id: Number(id) }).then(res => {
            setDetail(res.data.detail);
            setStepList(res.data.order_process_step_detail_list.sort((a, b) => a.sort - b.sort));
            let currentStep = res.data.order_process_step_detail_list.find(step => step.is_current_step);
            if (currentStep) {
                setDefaultActiveKey(`${currentStep.id}`);
            } else {
                setDefaultActiveKey("base");
            }
        });
    }

    const toStart = (id: number) => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleFilled />,
            content: '开启执行此步骤后会同步开始计时，确定开启执行此步骤吗？',
            onOk() {
                return new Promise((resolve, reject) => {
                    executeStart({ order_process_step_detail_id: id })
                        .then(res => {
                            resolve(res);
                            loadData();
                        })
                        .catch(e => { reject(e); })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    }

    const toFinish = (step: IOrderDetailStep) => {
        navigate(`/order/finish/${step.id}?location=${step.order_process_step.process_step_is_location}&attachment=${step.order_process_step.process_step_is_need_upload}`);
    }

    function StepTimer({ start }: { start: string }) {
        const [clock, setClock] = useState(0);

        useEffect(() => {
            let timer = setInterval(() => {
                setClock((dayjs().diff(dayjs(start))));
            }, 500);
            return () => {
                clearInterval(timer);
            };
        }, []);

        return <div style={{ color: 'grey', textAlign: "center" }}>{`已进行${Math.floor(clock / 1000 / 60 / 60)}小时${dayjs(clock).format('mm')}分${dayjs(clock).format('ss')}秒`}</div>
    }

    return (
        <div className='menu inner-page'>
            <div className='menu__title'>
                <div>项目信息</div>
            </div>

            {
                detail && stepList && defaultActiveKey ?
                    <Collapse accordion defaultActiveKey={defaultActiveKey}>
                        <Panel header="基础信息" key="base">
                            <Descriptions title={detail.title} layout="vertical">
                                <Descriptions.Item label="项目金额（元）">{detail.money}</Descriptions.Item>
                                <Descriptions.Item label="当前跟进步骤">{detail.process_title ?? "无"}</Descriptions.Item>
                                <Descriptions.Item label="附件">
                                    {
                                        detail.attach_files_info.length > 0 ?
                                            <List
                                                className='record__attachment'
                                                itemLayout="horizontal"
                                                dataSource={detail.attach_files_info}
                                                renderItem={item => (
                                                    <li>
                                                        <Card bodyStyle={{ padding: "0 10px" }}>
                                                            <div className='record__attachment__card'>
                                                                <div>{item.attach_name}</div>
                                                                <Button type="text" icon={<DownloadOutlined />} href={item.attach_url_link} target="_blank" />
                                                            </div>
                                                        </Card>
                                                    </li>
                                                )}
                                            /> : "无"
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        </Panel>
                        {
                            stepList?.map((step, index) => {
                                let process = step.order_process_step;
                                return (
                                    <Panel header={`步骤${index + 1}${defaultActiveKey === String(step.id) ? '（当前步骤）' : ''}`} key={step.id}>
                                        <Descriptions title={process.process_step_title}>
                                            <Descriptions.Item label="步骤描述">{process.process_step_desc ?? '无'}</Descriptions.Item>
                                            <Descriptions.Item label="需执行时间日期"><span style={{ color: process.is_overtime ? 'red' : undefined }}>{process.predict_execute_time ?? '无'}</span></Descriptions.Item>
                                            <Descriptions.Item label="状态">{process.status_text}</Descriptions.Item>
                                            <Descriptions.Item label="附件">
                                                {
                                                    process.process_step_attach_files_info.length > 0 ?
                                                        <List
                                                            className='record__attachment'
                                                            itemLayout="horizontal"
                                                            dataSource={process.process_step_attach_files_info}
                                                            renderItem={item => (
                                                                <li>
                                                                    <Card bodyStyle={{ padding: "0 10px" }}>
                                                                        <div className='record__attachment__card'>
                                                                            <div>{item.attach_name}</div>
                                                                            <Button type="text" icon={<DownloadOutlined />} href={item.attach_url_link} target="_blank" />
                                                                        </div>
                                                                    </Card>
                                                                </li>
                                                            )}
                                                        /> : "无"
                                                }
                                            </Descriptions.Item>
                                        </Descriptions>
                                        {
                                            step.status === 30
                                                ? <Descriptions title="我提交的">
                                                    <Descriptions.Item label="步骤描述">{step.desc ?? '无'}</Descriptions.Item>
                                                    <Descriptions.Item label="耗时">{step.time_consuming_text ?? '无'}</Descriptions.Item>
                                                    <Descriptions.Item label="附件">
                                                        {
                                                            step.attach_files_info.length > 0 ?
                                                                <List
                                                                    className='record__attachment'
                                                                    itemLayout="horizontal"
                                                                    dataSource={step.attach_files_info}
                                                                    renderItem={item => (
                                                                        <li>
                                                                            <Card bodyStyle={{ padding: "0 10px" }}>
                                                                                <div className='record__attachment__card'>
                                                                                    <div>{item.attach_name}</div>
                                                                                    <Button type="text" icon={<DownloadOutlined />} href={item.attach_url_link} target="_blank" />
                                                                                </div>
                                                                            </Card>
                                                                        </li>
                                                                    )}
                                                                /> : "无"
                                                        }
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                : null
                                        }

                                        {
                                            step.can_begin_execute
                                                ? <Button type="primary" block onClick={() => toStart(step.id)}>开始执行任务</Button>
                                                : null
                                        }
                                        {
                                            step.can_end_execute && step.execute_begin_at
                                                ? <div style={{ marginBottom: 10 }}><StepTimer start={step.execute_begin_at}></StepTimer></div>
                                                : null
                                        }
                                        {
                                            step.can_end_execute
                                                ? <Button type="primary" block danger onClick={() => toFinish(step)}>停止执行任务</Button>
                                                : null
                                        }
                                    </Panel>
                                );
                            })
                        }
                    </Collapse>
                    : null
            }
        </div>
    );
}


export default OrderDetailPage;