import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// antd
import { Grid, Col, Form, Input, message, Row, Select, Upload, Modal, Table, Space, Dropdown, Menu, Pagination, Card, Empty, InputNumber, Divider } from 'antd';
import { Button } from 'antd-mobile'

import { CheckList, Popup, SearchBar } from 'antd-mobile'
import { getFriendsList, IFriends } from '../../apis/friends';
import { IToInviteForm, toInvite } from '../../apis/invitation';

import './invitationStepAddStaff.scss';
import React from 'react';

const { Option } = Select;
const { useBreakpoint } = Grid;
const { Column } = Table;


interface CollectionCreateFormProps {
    data?: { id: number, remain_assign_money: number },
    onFinish?: () => void;
}

function InvitationStepAddStaffFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();

    const [form] = Form.useForm<IToInviteForm>();


    const [money, setMoney] = useState(0);
    const [searchParams, _] = useSearchParams();
    useEffect(() => {
        reset();

        form.setFieldsValue({
            id: data?.id ?? Number(searchParams.get('id')),
        });
        setMoney(data?.remain_assign_money ?? Number(searchParams.get('m')));
    }, [data, searchParams]);

    const [items, setItems] = useState<IFriends[]>([]);
    useEffect(() => {
        getFriendsList({ page: 1, size: 10000 }).then(res => {
            setItems(res.data.list);
        })
    }, []);
    const [visible, setVisible] = useState(false);
    const [selected, setSelected] = useState<string[]>([]);
    const [searchText, setSearchText] = useState('');
    const filteredItems = useMemo(() => {
        if (searchText) {
            return items.filter(item => (item.name + item.mobile).includes(searchText))
        } else {
            return items
        }
    }, [items, searchText]);
    const setFormContent = (item: string[]) => {
        form.setFieldsValue({
            adviser_list: item.map(e => {
                const [id, name] = e.split(":");
                return {
                    id: Number(id),
                    name,
                }
            })
        })
    }

    const reset = () => {
        form.resetFields();
        setSelected([]);
        setSearchText('');
    }

    const [loading, setLoading] = useState(false);
    const onSubmit = async () => {
        await form.validateFields();

        let _form = form.getFieldsValue(true);
        setLoading(true);
        try {
            let res = await toInvite({
                ..._form
            });
            message.success(res.msg);
            if (onFinish) {
                onFinish();
            } else {
                navigate(-1);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className={(screens.xs ? 'inner-page mobile' : '')}>
            {
                screens.xs
                    ? <Space align='center'>
                        <Button
                            onClick={() => {
                                setVisible(true)
                            }}
                        >
                            邀请好友
                        </Button>
                        <div>{selected.length > 0 ? `你选择了 ${selected.map(item => item.split(':')[1])}` : '请选择要邀请的好友'}</div>
                    </Space>
                    : <Form.Item
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="请选择要邀请的好友"
                            onChange={(val) => {
                                setSelected(val)
                                setFormContent(val);
                            }}
                            optionLabelProp="label"
                            value={selected}
                        >
                            {
                                items.map(item => (
                                    <Option value={`${item.id}:${item.name}`} label={item.name}>
                                        <Space>
                                            <span >{item.name}</span >
                                            <span >（{item.mobile}）</span >
                                        </Space>
                                    </Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
            }



            <Divider></Divider>

            <h3>可分配金额：{money}元</h3>

            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    noStyle
                    shouldUpdate={
                        (prevValues, curValues) =>
                            prevValues.adviser_list !== curValues.adviser_list
                    }
                >
                    {({ getFieldValue }) => {
                        const adviserList: {
                            id: number,
                            name: string,
                            money: number
                        }[] = getFieldValue('adviser_list');
                        return (
                            adviserList?.map((item, index) => (
                                <Form.Item
                                    label={`分配金额 - ${item.name}`}
                                    name={['adviser_list', index, 'money']}
                                    rules={[{ required: true, message: '请分配金额' }]}
                                >
                                    <InputNumber min={0} max={money} addonAfter="元" style={{ width: "100%" }} />
                                </Form.Item>
                            ))
                        )
                    }}
                </Form.Item>
            </Form>

            <Divider></Divider>

            <Button block color='primary' size='large' disabled={selected.length === 0} onClick={onSubmit} loading={loading}>
                提交
            </Button>

            <Popup
                visible={visible}
                onMaskClick={() => {
                    setVisible(false)
                }}
                destroyOnClose
            >
                <div className="searchBarContainer">
                    <SearchBar
                        placeholder='输入文字搜索'
                        value={searchText}
                        onChange={v => {
                            setSearchText(v)
                        }}
                    />
                </div>
                <div className="checkListContainer">
                    <CheckList
                        className="myCheckList"
                        multiple
                        value={selected}
                        onChange={val => {
                            setSelected(val)
                            setFormContent(val);
                        }}
                    >
                        {filteredItems.map(item => (
                            <CheckList.Item key={item.id} value={String(item.id) + `:${item.name}`}>
                                {item.name} （{item.mobile}）
                            </CheckList.Item>
                        ))}
                    </CheckList>
                </div>
            </Popup>
        </div>
    );
}

export default InvitationStepAddStaffFormModal;