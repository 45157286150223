import { axiosInstance, CommonResponse } from ".";
import { IStaffData, Pager } from "./common";

export type INotificationType = {
    100: '项目邀请',
    200: '好友添加',
}

export interface IMessage {
    id: number,
    category: number,
    type: keyof INotificationType,
    receiver_role_type: number,
    receiver: number,
    sender_role_type: number,
    sender: number,
    title: string,
    content: string,
    entity_id: number,
    is_read: boolean,
    is_operate: boolean,
    read_at: string,
    created_at: string,
    category_text: string,
    type_text: string,
    receiver_info: IStaffData
}

export interface IMessageList {
    list: IMessage[],
    total: number
}

export interface IMessageListSearchForm extends Pager {
    type?: number
}

/**
 * 消息中心列表
 * @returns 
 */
export const getMessageList = (data: IMessageListSearchForm): Promise<CommonResponse<IMessageList>> => {
    return axiosInstance.post('/adviser/notification/list', data).then(res => res.data);
};

export interface IMessageUnreadCount {
    count: number
}

/**
 * 未读消息数量
 * @returns 
 */
export const getMessageUnreadCount = (): Promise<CommonResponse<IMessageUnreadCount>> => {
    return axiosInstance.post('/adviser/notification/unread_count').then(res => res.data);
};



/**
 * 设置已读
 * @returns 
 */
export const setMessageRead = (data: { id: number }): Promise<CommonResponse> => {
    return axiosInstance.post('/adviser/notification/set_read', data).then(res => res.data);
};


/**
 * 设置全部已读
 * @returns 
 */
export const setAllMessageRead = (): Promise<CommonResponse> => {
    return axiosInstance.post('/adviser/notification/set_all_read').then(res => res.data);
};
