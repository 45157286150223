import functionIcon from "@assets/navigator/function_black.svg";
import indexIcon from "@assets/navigator/index_black.svg";
import mineIcon from "@assets/navigator/mine_black.svg";
import addressIcon from "@assets/navigator/address_black.svg";

import { Badge } from "antd";
import { useNavigate } from "react-router-dom";
import './bottomNavigator.scss'

function BottomNavigatorBar({ current }: { current: number }) {
    // 导航栏
    const navList = [
        {
            name: '业务管理',
            path: '/order',
            icon: functionIcon,
            badge: 0
        },
        {
            name: '邀请管理',
            path: "/invitation",
            icon: addressIcon,
            badge: 0
        },
        {
            name: '结算管理',
            path: "/settlement",
            icon: indexIcon,
            badge: 0
        },
        {
            name: '好友管理',
            path: "/friends",
            icon: mineIcon,
            badge: 0
        }
    ];

    const nav = useNavigate();

    return (
        <div className="bottom-navigator">
            {
                navList.map((e, i) => {
                    return (
                        <div
                            className={`bottom-navigator__item ${i === current ? 'active' : ''}`}
                            onClick={() => nav(e.path)}
                        >
                            <Badge offset={[-10, 10]} count={e.badge} overflowCount={99}>
                                <img className='bottom-navigator__item__img' src={e.icon} alt={e.name} />
                            </Badge>
                            <div>{e.name}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default BottomNavigatorBar;
