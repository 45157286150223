import React, { useEffect } from 'react';
import { ChangeEvent, useState } from 'react';
import Compressor from 'compressorjs';
import { useNavigate } from 'react-router';
import dayjs, { Dayjs } from 'dayjs';

import { LocationCascader } from '../../../components/form/locationCascader';

// assets
import avatarImg from '@assets/images/register/avatar.png';
import idcardBackImg from '@assets/images/register/idcard_back.png';
import idcardFrontImg from '@assets/images/register/idcard_front.png';

// antd
import { Button, Card, Col, DatePicker, Form, Input, message, Row, Select, Spin, Upload, UploadFile } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

// interface
import { UploadRequestOption } from "rc-upload/lib/interface";
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { Attachment } from '../../../apis/attachment';

// apis
import { FileUpload, getDictList, getIdcardOcrData, IDictData, uploadFile } from '../../../apis/common';
import { register } from '../../../apis/user';

// style
import '../login.scss';
import './register.scss';

const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 * 注册员工页
 */
function RegisterStaff() {
    // 路由
    const navigate = useNavigate();

    // 表单实例
    const [form] = Form.useForm<any>();

    // 表单提交
    const [submitLoading, setSubmitLoading] = useState(false);
    const onSubmit = async (values: any) => {
        let _form = form.getFieldsValue(true);

        setSubmitLoading(true);
        console.log('Received values of form: ', _form);
        try {
            let res = await register({
                ..._form,
                province_id: _form.cur_id ? _form.cur_id[0] : 0,
                city_id: _form.cur_id ? _form.cur_id[1] : 0,
                area_id: _form.cur_id ? _form.cur_id[2] : 0,
                street_id: _form.cur_id ? _form.cur_id[3] : 0,
                attach_certification_data: attachments.filter(list => list && list.length > 0).map((list, index) => {
                    return {
                        name: certificationType?.options[index].name,
                        value: certificationType?.options[index].value,
                        files: list
                            .filter((file: UploadFile<any>) => file.response)
                            .map((file: UploadFile<any>) => {
                                let res: FileUpload | Attachment = file.response;
                                return (res as FileUpload).file_id || (res as Attachment).id;
                            })
                    }
                })
            });
            message.success(res.msg);
            navigate('/register/pending', { replace: true });
        } catch (e) {
            console.error(e);
        } finally {
            setSubmitLoading(false);
        }
    };




    // 上传头像
    const [inputAvatarLoading, setInputAvatarLoading] = useState(false);
    const inputAvatarRef = React.createRef<HTMLInputElement>();
    const [inputAvatarImg, setInputAvatarImg] = useState<string>();
    const uploadAvatar = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        let files = event.target.files;
        setInputAvatarLoading(true);

        // 压缩
        new Compressor(files[0], {
            quality: 0.6,
            success(result) {
                let file = new File([result], files[0].name);
                uploadFile({ file: file, type: 'adviser_logo' }).then(res => {
                    setInputAvatarImg(res.data.full_link);
                    form.setFieldValue('avatar_id', res.data.file_id);
                    setInputAvatarLoading(false);
                });
            },
            error(err) {
                console.log(err.message);
                message.error('压缩过程出现错误');
                setInputAvatarLoading(false);
            },
        });
    };

    // 上传身份证国徽面
    const [inputIdcardFrontLoading, setInputIdcardFrontLoading] = useState(false);
    const inputIdcardFrontRef = React.createRef<HTMLInputElement>();
    const [inputIdcardFrontImg, setInputIdcardFrontImg] = useState<string>();
    const uploadIdcardFront = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        let files = event.target.files;
        setInputIdcardFrontLoading(true);

        // 压缩
        new Compressor(files[0], {
            quality: 0.6,
            success(result) {
                let file = new File([result], files[0].name);
                uploadFile({ file: file, type: 'idcard' }).then(res => {
                    setInputIdcardFrontImg(res.data.full_link);
                    form.setFieldValue('idcard_front_img', res.data.file_id);
                    getIdcardOcrData({ fid: res.data.file_id }).then(res => {
                        let data = res.data;
                        form.setFieldValue('is_idcard_long_time', data.is_idcard_long_time);
                        if (data.valid_date_start && data.valid_date_end) {
                            form.setFieldValue('idcard_date', [dayjs(data.valid_date_start), dayjs(data.valid_date_end)]);
                        } else if (data.is_idcard_long_time && data.valid_date_start) {
                            form.setFieldValue('idcard_date_long', dayjs(data.valid_date_start));
                        }
                        form.setFieldsValue({
                            idcard_begin_date: data.valid_date_start,
                            idcard_expire_date: data.valid_date_end,
                        });
                    });
                    setInputIdcardFrontLoading(false);
                });
            },
            error(err) {
                console.log(err.message);
                message.error('压缩过程出现错误');
                setInputIdcardFrontLoading(false);
            },
        });
    };
    // 上传身份证人像面
    const [inputIdcardBackLoading, setInputIdcardBackLoading] = useState(false);
    const inputIdcardBackRef = React.createRef<HTMLInputElement>();
    const [inputIdcardBackImg, setInputIdcardBackImg] = useState<string>();
    const uploadIdcardBack = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        let files = event.target.files;
        setInputIdcardBackLoading(true);

        // 压缩
        new Compressor(files[0], {
            quality: 0.6,
            success(result) {
                let file = new File([result], files[0].name);
                uploadFile({ file: file, type: 'idcard' }).then(res => {
                    setInputIdcardBackImg(res.data.full_link);
                    form.setFieldValue('idcard_back_img', res.data.file_id);
                    getIdcardOcrData({ fid: res.data.file_id }).then(res => {
                        let data = res.data;
                        form.setFieldsValue({
                            name: data.name,
                            idcard: data.idcard,
                            address: data.address,
                        });
                    });
                    setInputIdcardBackLoading(false);
                });
            },
            error(err) {
                console.log(err.message);
                message.error('压缩过程出现错误');
                setInputIdcardBackLoading(false);
            },
        });
    };

    // 身份证有效期时间段
    const handleIdcardDateChange = (_: any, formatString: [string, string]) => {
        form.setFieldsValue({
            idcard_begin_date: formatString[0],
            idcard_expire_date: formatString[1],
        })
    }
    const handleIdcardLongDateChange = (date: Dayjs | null, dateString: string) => {
        form.setFieldsValue({
            idcard_begin_date: dateString,
        })
    }

    // 认证附件集合
    const [certificationType, setCertificationType] = useState<IDictData>();
    useEffect(() => {
        getDictList(['talent_attachment_type']).then(res => {
            setCertificationType(res.data.talent_attachment_type);
        });
    }, []);

    // 上传附件
    const [attachments, setAttachments] = useState<UploadFile<any>[][]>([]);
    const checkFileSize = (file: RcFile) => {
        if ((file.size / 1024 / 1024) > 50) {
            message.error(`${file.name} 文件超出限制大小`);
            return Upload.LIST_IGNORE;
        }
        return true;
    };
    const onFileChange = (info: UploadChangeParam<UploadFile<any>>, index: number) => {
        console.log(info.fileList);
        attachments[index] = info.fileList;
        setAttachments(attachments);
        // hack
        form.setFieldValue(`attachments_${index}`, Math.random());
    }
    const customRequest = (options: UploadRequestOption<any>) => {
        const { onSuccess, onError, file, onProgress } = options;
        uploadFile({ file: file as Blob, type: 'default' })
            .then(res => {
                onSuccess!(res.data);
            });
    }
    const onAttachmentDownload = (file: UploadFile<any>) => {
        console.log(file);
        let res: FileUpload | Attachment = file.response;
        window.open((res as FileUpload).full_link || (res as Attachment).attach_url_link, "_blank");
    }

    return (
        // 流式布局
        <div className='login-form register'>
            {/* 标题（登录方式） */}
            <div className='login-form__title'>注册</div>

            {/* 表单 */}
            <Form
                form={form}
                name="registerForm"
                className="login-form__form"
                layout='vertical'
                onFinish={onSubmit}
            >
                {/* 头像 */}
                <Form.Item noStyle>
                    <Row style={{ margin: "20px 0" }}>
                        <Col xs={2} sm={3} md={4} lg={5} xl={6}></Col>
                        <Col xs={20} sm={18} md={16} lg={14} xl={12}>
                            <Spin spinning={inputAvatarLoading}>
                                <img
                                    src={inputAvatarImg ?? avatarImg}
                                    style={{ width: "100%", height: 200, cursor: 'pointer', objectFit: 'contain' }}
                                    alt="头像"
                                    onClick={() => inputAvatarRef.current?.click()}
                                />
                            </Spin>

                            <input
                                ref={inputAvatarRef}
                                style={{ display: 'none' }}
                                type="file"
                                accept='image/jpeg,image/jpg,image/png'
                                onChange={uploadAvatar}
                            />
                        </Col>
                    </Row>
                </Form.Item>

                {/* 身份证正反面 */}
                <Form.Item noStyle>
                    <Row style={{ margin: "20px 0" }}>
                        <Col span={5}></Col>
                        <Col span={6}>
                            <Spin spinning={inputIdcardBackLoading}>
                                <img
                                    src={inputIdcardBackImg ?? idcardBackImg}
                                    style={{ width: "100%", height: 200, cursor: 'pointer', objectFit: 'contain' }}
                                    alt="身份证人像面"
                                    onClick={() => inputIdcardBackRef.current?.click()}
                                />
                            </Spin>

                            <input
                                ref={inputIdcardBackRef}
                                style={{ display: 'none' }}
                                type="file"
                                accept='image/jpeg,image/jpg,image/png'
                                onChange={uploadIdcardBack}
                            />
                        </Col>
                        <Col span={2}></Col>
                        <Col span={6}>
                            <Spin spinning={inputIdcardFrontLoading}>
                                <img
                                    src={inputIdcardFrontImg ?? idcardFrontImg}
                                    style={{ width: "100%", height: 200, cursor: 'pointer', objectFit: 'contain' }}
                                    alt="身份证国徽面"
                                    onClick={() => inputIdcardFrontRef.current?.click()}

                                />
                            </Spin>
                            <input
                                ref={inputIdcardFrontRef}
                                style={{ display: 'none' }}
                                type="file"
                                accept='image/jpeg,image/jpg,image/png'
                                onChange={uploadIdcardFront}
                            />
                        </Col>
                        <Col span={5}></Col>
                    </Row>
                </Form.Item>

                {/* 姓名 */}
                <Form.Item
                    name="name"
                    rules={[{ required: true, message: '请输入姓名' }]}
                >
                    <Input placeholder="姓名" maxLength={10} />
                </Form.Item>

                {/* 身份证号 */}
                <Form.Item
                    name="idcard"
                    rules={[{ required: true, message: '请输入身份证号' }]}
                >
                    <Input placeholder="身份证号" maxLength={18} />
                </Form.Item>

                <Form.Item
                    name="is_idcard_long_time"
                    rules={[{ required: true, message: '请选择身份证是否长期' }]}
                >
                    <Select
                        showArrow
                        placeholder="身份证是否长期"
                    >
                        <Option key={1} value={true}>长期身份证</Option>
                        <Option key={2} value={false}>非长期身份证</Option>
                    </Select>
                </Form.Item>

                {/* 身份证有效期 */}
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => prevValues.is_idcard_long_time !== curValues.is_idcard_long_time}
                >
                    {({ getFieldValue, resetFields }) => {
                        let isLongTime = getFieldValue('is_idcard_long_time');
                        return (
                            isLongTime
                                ? <Form.Item
                                    name="idcard_date_long"
                                    rules={[{ required: true, message: '请选择身份证有效期' }]}
                                    shouldUpdate
                                >
                                    <DatePicker
                                        style={{ 'width': '100%' }}
                                        onChange={handleIdcardLongDateChange}
                                        format='YYYY-MM-DD'
                                        placeholder='身份证有效期开始时间'
                                    />
                                </Form.Item>
                                : <Form.Item
                                    name="idcard_date"
                                    rules={[{ required: true, message: '请选择身份证有效期' }]}
                                    shouldUpdate
                                >
                                    <RangePicker
                                        style={{ 'width': '100%' }}
                                        onChange={handleIdcardDateChange}
                                        format='YYYY-MM-DD'
                                        placeholder={['身份证有效期开始时间', '身份证有效期结束时间']}
                                    />
                                </Form.Item>
                        )
                    }}
                </Form.Item>

                {/* 详细地址 */}
                <Form.Item
                    name="address"
                    rules={[{ required: true, message: '请输入详细地址' }]}
                >
                    <Input placeholder="详细地址" maxLength={100} />
                </Form.Item>

                {/* 地址 */}
                <Form.Item
                    name="cur_id"
                    rules={[{ required: true, message: '请选择现住区域' }]}
                >
                    <LocationCascader deep={4} placeholder="请选择现住区域" />
                </Form.Item>

                {/* 企业名称 */}
                <Form.Item
                    name="company_name"
                >
                    <Input placeholder="在职企业名称" maxLength={20} />
                </Form.Item>

                {/* 部门名称 */}
                <Form.Item
                    name="dept_name"
                >
                    <Input placeholder="在职部门名称" maxLength={20} />
                </Form.Item>

                {
                    certificationType?.options.map((item, index) => (
                        <Form.Item
                            label={item.name}
                            shouldUpdate={(prevValues, curValues) => prevValues[`attachments_${index}`] !== curValues[`attachments_${index}`]}
                        >
                            {({ getFieldValue }) => {
                                let a = attachments[index] ?? [];
                                return (
                                    <Card>
                                        <Upload
                                            fileList={a}
                                            beforeUpload={checkFileSize}
                                            onChange={(info) => onFileChange(info, index)}
                                            customRequest={customRequest}
                                            showUploadList={{ showDownloadIcon: true }}
                                            onDownload={onAttachmentDownload}
                                        >
                                            <Button icon={<UploadOutlined />}>点击上传附件</Button>
                                        </Upload>
                                    </Card>
                                )
                            }}
                        </Form.Item>
                    ))
                }

                {/* 登录按钮 */}
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={submitLoading} block className="login-form__form__button">
                        提交审核
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}


export default RegisterStaff;