import React, { useEffect, useState } from 'react';
import { useDebounce } from 'ahooks';

// interface

// antd
import { Space, Table, Dropdown, Menu, Button, Input, Select, Grid, Pagination, Empty, Card } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getSettlementList, ISettlement, ISettlementListSearchForm } from '../../apis/settlement';
import BottomNavigatorBar from '../../components/bottomNavigator/bottomNavigator';

const { Column } = Table;
const { Option } = Select;
const { useBreakpoint } = Grid;

function SettlementListPage() {
    const navigate = useNavigate();
    const screens = useBreakpoint();

    // 搜索表单
    const [form, setForm] = useState<ISettlementListSearchForm>({
        page: 1,
        size: 10,
    });

    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(ISettlement)[]>([]);
    const [mListData, setMListData] = useState<(ISettlement)[]>([]);
    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: ISettlementListSearchForm) => {
        let _form = newForm ?? form;
        setLoading(true);
        try {
            let res = await getSettlementList(_form);
            setTotal(res.data.total);
            setMListData(res.data.list);
            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((_form.page! - 1) * _form.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    const resetData = () => {
        let newForm: ISettlementListSearchForm = {
            page: 1,
            size: 10,
        }
        setForm(newForm);
        loadData(newForm);
    }

    // 排序
    const handleSortChange = (value: string) => {
        let newForm = {
            ...form,
            updated_at: value
        };
        setForm(newForm);
        loadData(newForm);
    };

    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(form.keywords, { wait: 500 });
    useEffect(() => {
        loadData();
    }, [keywordsDebouncedValue]);
    const handleKeywordsChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            keywords: value.target.value
        };
        setForm(newForm);
    }

    const toDetail = (record: ISettlement) => {
        navigate(`/settlement/detail/${record.id}`);
    }

    return (
        <div className='address inner-page'>
            <div className='search'>

                <Select
                    className="search__item"
                    allowClear
                    showArrow
                    value={form.updated_at}
                    onChange={handleSortChange}
                    placeholder="时间排序"
                >
                    <Option key="asc">升序</Option>
                    <Option key="desc">降序</Option>
                </Select>

                <Input
                    className="search__item"
                    value={form.keywords}
                    onChange={handleKeywordsChange}
                    placeholder="关键词搜索"
                />

                <Button
                    className="search__button"
                    icon={<CloseOutlined />}
                    loading={loading}
                    onClick={() => resetData()}
                >
                    重置筛选
                </Button>
            </div>
            {
                screens.xs
                    ? <div>
                        {
                            mListData.length > 0 ? mListData.map(item =>
                                <Card className='m-card' onClick={() => toDetail(item)}>
                                    <div className='m-card_item'>
                                        <div>企业名称：</div>
                                        <div>{item.company?.name ?? "无"}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>项目：</div>
                                        <div>{item.title}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>流程：</div>
                                        <div>{item.process_title}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>结算金额：</div>
                                        <div>{item.settlement_money_total}元</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>项目状态：</div>
                                        <div>{item.status_text}</div>
                                    </div>
                                </Card>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>

                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                            simple
                        />
                    </div>
                    : <Table
                        dataSource={listData}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column title="企业名称" dataIndex={["company", "name"]} key="name" />
                        <Column title="项目" dataIndex="title" key="title" />
                        <Column title="流程" dataIndex="process_title" key="process_title" />
                        <Column title="结算金额（元）" dataIndex="settlement_money_total" key="settlement_money_total" />
                        <Column title="项目状态" dataIndex="status_text" key="status_text" />
                        <Column
                            title="操作"
                            key="action"
                            fixed="right"
                            render={(_: any, record: ISettlement) => (
                                <Space>
                                    <Dropdown.Button
                                        trigger={['click']}
                                        size='small'
                                        type="primary"
                                        // loading={}
                                        overlay={
                                            <Menu
                                                items={[
                                                    {
                                                        key: 'detail',
                                                        label: (
                                                            <div>查看评价</div>
                                                        ),
                                                        onClick: () => toDetail(record),
                                                    },
                                                ]}
                                            />
                                        }>
                                        操作
                                    </Dropdown.Button>
                                </Space>
                            )}
                        />
                    </Table>
            }

            {screens.xs ? <BottomNavigatorBar current={2} /> : null}
        </div>
    );
}

export default SettlementListPage;