import { useEffect, useState } from 'react';

import { getDictList, IDictData } from '../../apis/common';
import { getInvitationLogs, IInvitationLogsListItem, IInvitationLogsSearchForm } from '../../apis/invitation';

// antd
import { Grid, Table, Pagination, Card, Empty, Select, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const { useBreakpoint } = Grid;
const { Column } = Table;
const { Option } = Select;

function InvitationLogsListModal() {
    const screens = useBreakpoint();

    // 搜索表单
    const [form, setForm] = useState<IInvitationLogsSearchForm>({
        page: 1,
        size: 10,
    });

    const [loading, setLoading] = useState(false);

    const [list, setList] = useState<IInvitationLogsListItem[]>([]);
    const [mListData, setMListData] = useState<(IInvitationLogsListItem)[]>([]);
    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: IInvitationLogsSearchForm) => {
        let _form = newForm ?? form;
        setLoading(true);
        try {
            let res = await getInvitationLogs(_form);
            setTotal(res.data.total);
            setMListData(res.data.list);
            setList((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((_form.page! - 1) * _form.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    const resetData = () => {
        let newForm: IInvitationLogsSearchForm = {
            page: 1,
            size: 10,
        }
        setForm(newForm);
        loadData(newForm);
    }

    // 邀请状态
    const [statusType, setStatusType] = useState<IDictData>();
    const handleStatusTypeChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                status: value,
            };
            loadData(newForm);
            return newForm;
        });
    };
    useEffect(() => {
        getDictList(['order_group_participation_status']).then(res => {
            setStatusType(res.data.order_group_participation_status);
        });
    }, []);

    return (
        <div className={'menu ' + (screens.xs ? 'inner-page mobile' : '')}>
            <div className='menu__title'>
                <div>邀请记录</div>
            </div>

            <div className='search'>
                <Select
                    allowClear
                    className="search__item"
                    showArrow
                    placeholder="信息来源"
                    value={form.status}
                    onChange={handleStatusTypeChange}
                >
                    {statusType?.options.map(option => (
                        <Option key={option.value} value={option.value}>{option.name}</Option>
                    ))}
                </Select>

                <Button
                    className="search__button"
                    icon={<CloseOutlined />}
                    loading={loading}
                    onClick={() => resetData()}
                >
                    重置筛选
                </Button>
            </div>

            {
                screens.xs
                    ? <div>
                        {
                            mListData.length > 0 ? mListData.map(item =>
                                <Card className='m-card'>
                                    <div className='m-card_item'>
                                        <div>订单名称：</div>
                                        <div>{item.order_group.order_title}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>流程：</div>
                                        <div>{item.process.title}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>总金额：</div>
                                        <div>{item.order_group.order_money}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>邀请情况：</div>
                                        <div>{item.status_text}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>邀请步骤：</div>
                                        <div>{item.order_group_process_step.process_step_title}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>步骤分配金额：</div>
                                        <div>{item.money}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>被邀请人：</div>
                                        <div>{item.adviser?.name ?? '无'}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>邀请时间：</div>
                                        <div>{item.invite_at}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>反馈时间：</div>
                                        <div>{item.confirm_at ?? '无'}</div>
                                    </div>
                                </Card>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>

                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                            simple
                        />
                    </div>
                    : <Table
                        dataSource={list}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column title="订单名称" dataIndex={['order_group', 'order_title']} key="order_title" />
                        <Column title="流程" dataIndex={["process", 'title']} key="process_title" />
                        <Column title="总金额" dataIndex={["order_group", 'order_money']} key="order_money" />
                        <Column title="邀请情况" dataIndex="status_text" key="status_text" />
                        <Column title="邀请步骤" dataIndex={['order_group_process_step', 'process_step_title']} key="process_step_title" />
                        <Column title="步骤分配金额" dataIndex="money" key="money" />
                        <Column title="被邀请人" dataIndex={['adviser', 'name']} key="adviser_name" />
                        <Column title="邀请时间" dataIndex="invite_at" key="invite_at" />
                        <Column title="反馈时间" dataIndex="confirm_at" key="confirm_at" />
                    </Table>
            }
        </div>
    );
}

export default InvitationLogsListModal;