import { axiosInstance, CommonResponse } from ".";
import { Attachment } from "./attachment";
import { Pager } from "./common";
import { ICompany } from "./company";
import { IUserInfo } from "./user";

export interface IProcessStep {
    id: number,
    order_id: number,
    process_step_id: number,
    process_step_title: string,
    process_step_desc?: string,
    process_step_is_location: boolean,
    process_step_sort: number,
    process_step_attach_files: number[],
    status_text?: null,
    process_step_attach_files_info: Attachment[]
}

export interface ISettlement {
    id: number,
    title: string,
    status: number,
    money: number,
    process_id: number,
    company_id: number,
    process_title: string,
    is_settled: boolean,
    settlement_money_total: number,
    status_text: string,
    current_process_step: null,
    current_process_step_adviser: IUserInfo[],
    company: ICompany
}

export interface ISettlementList {
    list: ISettlement[],
    total: number
}

export interface ISettlementListSearchForm extends Pager {
    keywords?: string,
    is_settled?: number,
    updated_at?: string
}

/**
 * 获取结算管理列表
 * @returns 
 */
export const getSettlementList = (data: ISettlementListSearchForm): Promise<CommonResponse<ISettlementList>> => {
    return axiosInstance.post('/adviser/order_settlement/list', data).then(res => res.data);
};

export interface ISettlementDetail {
    id: number,
    title: string,
    money: number,
    process_id: number,
    process_title?: string,
    attach_files: number[],
    attach_files_info: Attachment[]

    is_appraise: boolean,
    appraise_type: number,
    status_text?: string,
    appraise_type_text: "分步骤评价",

    is_company_appraise: boolean,
}

export interface ISettlementDetailStep {
    id: number,
    order_process_step_id: number,
    order_id: number,
    process_id: number,
    process_step_id: number,
    sort: number,
    adviser_id: number,
    status: number,
    interrupt_at?: string,
    time_consuming?: number,
    time_consuming_text?: string,
    execute_time?: {
        begin_at: string,
        end_at: string
    }[],
    lat?: number,
    lng?: number,
    address?: string,
    desc?: string,
    attach_files: number[],
    attach_files_info: Attachment[]
    execute_begin_at: string,
    status_text: string,
    order_process_step: {
        id: number,
        order_id: number,
        process_id: number,
        process_step_id: number,
        process_step_title: string,
        process_step_desc?: string,
        process_step_commission_percent: number,
        process_step_is_location: boolean,
        process_step_sort: number,
        process_step_attach_files: number[],
        process_step_attach_files_info: Attachment[],
        status: number,
        status_text: string,
        score?: number,
        appraise?: string,
        process_step_interval_time: null,
        predict_execute_time: null,
        is_appraise: boolean,
        is_company_appraise: boolean,
        company_score?: number,
        company_appraise?: string,
        is_overtime: false,
    },
}

export interface ISettlementDetailRes {
    detail: ISettlementDetail,
    order_process_step_detail_list: ISettlementDetailStep[]
}

/**
 * 获取业务评价详情
 * @returns 
 */
export const getSettlementAppraiseDetail = (data: { order_id: number }): Promise<CommonResponse<ISettlementDetailRes>> => {
    return axiosInstance.post('/adviser/order_settlement/appraise', data).then(res => res.data);
};